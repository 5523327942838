import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Follow_up_stoppage extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            rows_1: [],
            rows_2: [],
            selected_datas: [],
            count: 0,
            filtered: [],
            txtsearch: '',

            wiz: 1,
            iscreate: false,
            id: -1,
            msg_status: '',

            all_movs: [],
            sum_movs: [],
            sums: [],
            transit_for_loading: [],
            transit_for_unloading: [],
            transit_for_heading: [],
            isexe: false,

            filter_datas: [],
        }
    }

    componentDidMount() {
        this.fetchdata()
        //this.demo()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicles_for_follow_up_stoppage;
        this.setState({
            datas: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                }, () => {
                    this.fetchdata2()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    demo = () => {
        let rows_1=[
            {
                "id": 1
            },
            {
                "id": 2
            },
            {
                "id": 3
            },
            {
                "id": 4
            },
            {
                "id": 5
            },
            {
                "id": 6
            },
            {
                "id": 7
            },
            {
                "id": 8
            },
            {
                "id": 9
            },
            {
                "id": 10
            },
            {
                "id": 11
            },
            {
                "id": 12
            },
            {
                "id": 13
            },
            {
                "id": 14
            },
            {
                "id": 15
            },
            {
                "id": 16
            },
            {
                "id": 17
            },
            {
                "id": 18
            },
            {
                "id": 19
            },
            {
                "id": 20
            },
            {
                "id": 21
            },
            {
                "id": 22
            },
            {
                "id": 23
            },
            {
                "id": 24
            },
            {
                "id": 25
            },
            {
                "id": 26
            },
            {
                "id": 27
            },
            {
                "id": 28
            },
            {
                "id": 29
            },
            {
                "id": 30
            },
            {
                "id": 31
            },
            {
                "id": 32
            },
            {
                "id": 33
            },
            {
                "id": 34
            },
            {
                "id": 35
            },
            {
                "id": 36
            },
            {
                "id": 37
            },
            {
                "id": 38
            },
            {
                "id": 39
            }
        ]
        let rows_2=[
            {
                "id": 1,
                "type": 1,
                "data": "Plate #"
            },
            {
                "id": 1,
                "type": 1,
                "data": "3-71778"
            },
            {
                "id": 2,
                "type": 1,
                "data": "Route"
            },
            {
                "id": 2,
                "type": 1,
                "data": "DUKEM (TO) LEMI"
            },
            {
                "id": 3,
                "type": 1,
                "data": "Total Stoppage"
            },
            {
                "id": 3,
                "type": 1,
                "data": "32:05:41"
            },
            {
                "id": 4,
                "type": -1,
                "data": "Assignment ID"
            },
            {
                "id": 4,
                "type": -1,
                "data": "Select"
            },
            {
                "id": 4,
                "type": -1,
                "data": "Remove"
            },
            {
                "id": 4,
                "type": 1,
                "data": "Location"
            },
            {
                "id": 4,
                "type": 1,
                "data": "Start Time"
            },
            {
                "id": 4,
                "type": 1,
                "data": "End Time"
            },
            {
                "id": 4,
                "type": 1,
                "data": "Stoppage"
            },
            {
                "id": 5,
                "type": -1,
                "data": 3770
            },
            {
                "id": 5,
                "type": 4,
                "data": 1
            },
            {
                "id": 5,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 5,
                "type": 2,
                "data": "Ring Road, Addis Ababa, Ethiopia"
            },
            {
                "id": 5,
                "type": 2,
                "data": "2023-11-11 11:04:33"
            },
            {
                "id": 5,
                "type": 2,
                "data": "2023-11-11 14:18:45"
            },
            {
                "id": 5,
                "type": 2,
                "data": "0:28:11"
            },
            {
                "id": 6,
                "type": -1,
                "data": 3770
            },
            {
                "id": 6,
                "type": 4,
                "data": 1
            },
            {
                "id": 6,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 6,
                "type": 2,
                "data": "4, Addis Ababa, Ethiopia"
            },
            {
                "id": 6,
                "type": 2,
                "data": "2023-11-11 14:46:56"
            },
            {
                "id": 6,
                "type": 2,
                "data": "2023-11-11 15:35:41"
            },
            {
                "id": 6,
                "type": 2,
                "data": "0:15:36"
            },
            {
                "id": 7,
                "type": -1,
                "data": 3770
            },
            {
                "id": 7,
                "type": 4,
                "data": 1
            },
            {
                "id": 7,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 7,
                "type": 2,
                "data": "4, Oromia Region, Ethiopia, 3.28 km from ዱከም / Dukem"
            },
            {
                "id": 7,
                "type": 2,
                "data": "2023-11-11 15:51:17"
            },
            {
                "id": 7,
                "type": 2,
                "data": "2023-11-11 16:25:29"
            },
            {
                "id": 7,
                "type": 2,
                "data": "0:39:41"
            },
            {
                "id": 8,
                "type": -1,
                "data": 3770
            },
            {
                "id": 8,
                "type": 4,
                "data": 1
            },
            {
                "id": 8,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 8,
                "type": 2,
                "data": "Dr Ali Bira Street, Adama, Oromia Region, Ethiopia"
            },
            {
                "id": 8,
                "type": 2,
                "data": "2023-11-11 17:05:10"
            },
            {
                "id": 8,
                "type": 2,
                "data": "2023-11-11 18:45:55"
            },
            {
                "id": 8,
                "type": 2,
                "data": "0:01:30"
            },
            {
                "id": 9,
                "type": -1,
                "data": 3770
            },
            {
                "id": 9,
                "type": 4,
                "data": 1
            },
            {
                "id": 9,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 9,
                "type": 2,
                "data": "NCSC ADAMA KOKA GARAGE"
            },
            {
                "id": 9,
                "type": 2,
                "data": "2023-11-12 09:44:01"
            },
            {
                "id": 9,
                "type": 2,
                "data": "2023-11-12 10:31:12"
            },
            {
                "id": 9,
                "type": 2,
                "data": "0:55:49"
            },
            {
                "id": 10,
                "type": -1,
                "data": 3770
            },
            {
                "id": 10,
                "type": 4,
                "data": 1
            },
            {
                "id": 10,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 10,
                "type": 2,
                "data": "NCSC ADAMA KOKA GARAGE"
            },
            {
                "id": 10,
                "type": 2,
                "data": "2023-11-12 11:27:01"
            },
            {
                "id": 10,
                "type": 2,
                "data": "2023-11-12 11:45:39"
            },
            {
                "id": 10,
                "type": 2,
                "data": "6:18:21"
            },
            {
                "id": 11,
                "type": -1,
                "data": 3770
            },
            {
                "id": 11,
                "type": 4,
                "data": 1
            },
            {
                "id": 11,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 11,
                "type": 2,
                "data": "NCSC ADAMA KOKA GARAGE"
            },
            {
                "id": 11,
                "type": 2,
                "data": "2023-11-13 09:05:36"
            },
            {
                "id": 11,
                "type": 2,
                "data": "2023-11-13 09:30:44"
            },
            {
                "id": 11,
                "type": 2,
                "data": "5:46:36"
            },
            {
                "id": 12,
                "type": -1,
                "data": 3770
            },
            {
                "id": 12,
                "type": 4,
                "data": 1
            },
            {
                "id": 12,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 12,
                "type": 2,
                "data": "NCSC ADAMA KOKA GARAGE"
            },
            {
                "id": 12,
                "type": 2,
                "data": "2023-11-13 15:17:20"
            },
            {
                "id": 12,
                "type": 2,
                "data": "2023-11-13 15:39:06"
            },
            {
                "id": 12,
                "type": 2,
                "data": "3:20:54"
            },
            {
                "id": 13,
                "type": -1,
                "data": 3770
            },
            {
                "id": 13,
                "type": 4,
                "data": 1
            },
            {
                "id": 13,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 13,
                "type": 2,
                "data": "NCSC ADAMA KOKA GARAGE"
            },
            {
                "id": 13,
                "type": 2,
                "data": "2023-11-14 13:25:38"
            },
            {
                "id": 13,
                "type": 2,
                "data": "2023-11-14 13:45:31"
            },
            {
                "id": 13,
                "type": 2,
                "data": "1:21:01"
            },
            {
                "id": 14,
                "type": -1,
                "data": 3770
            },
            {
                "id": 14,
                "type": 4,
                "data": 1
            },
            {
                "id": 14,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 14,
                "type": 2,
                "data": "NCSC ADAMA KOKA GARAGE"
            },
            {
                "id": 14,
                "type": 2,
                "data": "2023-11-14 15:06:32"
            },
            {
                "id": 14,
                "type": 2,
                "data": "2023-11-14 15:14:03"
            },
            {
                "id": 14,
                "type": 2,
                "data": "1:58:12"
            },
            {
                "id": 15,
                "type": -1,
                "data": 3770
            },
            {
                "id": 15,
                "type": 4,
                "data": 1
            },
            {
                "id": 15,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 15,
                "type": 2,
                "data": "NCSC ADAMA KOKA GARAGE"
            },
            {
                "id": 15,
                "type": 2,
                "data": "2023-11-14 17:12:15"
            },
            {
                "id": 15,
                "type": 2,
                "data": "2023-11-14 17:45:12"
            },
            {
                "id": 15,
                "type": 2,
                "data": "0:26:47"
            },
            {
                "id": 16,
                "type": -1,
                "data": 3770
            },
            {
                "id": 16,
                "type": 4,
                "data": 1
            },
            {
                "id": 16,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 16,
                "type": 2,
                "data": "NCSC ADAMA KOKA GARAGE"
            },
            {
                "id": 16,
                "type": 2,
                "data": "2023-11-14 18:11:59"
            },
            {
                "id": 16,
                "type": 2,
                "data": "2023-11-14 18:41:17"
            },
            {
                "id": 16,
                "type": 2,
                "data": "0:18:43"
            },
            {
                "id": 17,
                "type": -1,
                "data": 3770
            },
            {
                "id": 17,
                "type": 4,
                "data": 1
            },
            {
                "id": 17,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 17,
                "type": 2,
                "data": "NCSC ADAMA KOKA GARAGE"
            },
            {
                "id": 17,
                "type": 2,
                "data": "2023-11-15 07:50:20"
            },
            {
                "id": 17,
                "type": 2,
                "data": "2023-11-15 08:15:03"
            },
            {
                "id": 17,
                "type": 2,
                "data": "0:53:34"
            },
            {
                "id": 18,
                "type": -1,
                "data": 3770
            },
            {
                "id": 18,
                "type": 4,
                "data": 1
            },
            {
                "id": 18,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 18,
                "type": 2,
                "data": "NCSC ADAMA KOKA GARAGE"
            },
            {
                "id": 18,
                "type": 2,
                "data": "2023-11-15 09:08:37"
            },
            {
                "id": 18,
                "type": 2,
                "data": "2023-11-15 09:26:27"
            },
            {
                "id": 18,
                "type": 2,
                "data": "2:06:13"
            },
            {
                "id": 19,
                "type": -1,
                "data": 3770
            },
            {
                "id": 19,
                "type": 4,
                "data": 1
            },
            {
                "id": 19,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 19,
                "type": 2,
                "data": "NA"
            },
            {
                "id": 19,
                "type": 2,
                "data": "2023-11-16 06:00:00"
            },
            {
                "id": 19,
                "type": 2,
                "data": "2023-11-16 13:14:33"
            },
            {
                "id": 19,
                "type": 2,
                "data": "07:14:33"
            },
            {
                "id": 20,
                "type": 1,
                "data": "Plate #"
            },
            {
                "id": 20,
                "type": 1,
                "data": "3-89695"
            },
            {
                "id": 21,
                "type": 1,
                "data": "Route"
            },
            {
                "id": 21,
                "type": 1,
                "data": "DIRE DAWA (TO) JIGJIGA"
            },
            {
                "id": 22,
                "type": 1,
                "data": "Total Stoppage"
            },
            {
                "id": 22,
                "type": 1,
                "data": "22:25:25"
            },
            {
                "id": 23,
                "type": -1,
                "data": "Assignment ID"
            },
            {
                "id": 23,
                "type": -1,
                "data": "Select"
            },
            {
                "id": 23,
                "type": -1,
                "data": "Remove"
            },
            {
                "id": 23,
                "type": 1,
                "data": "Location"
            },
            {
                "id": 23,
                "type": 1,
                "data": "Start Time"
            },
            {
                "id": 23,
                "type": 1,
                "data": "End Time"
            },
            {
                "id": 23,
                "type": 1,
                "data": "Stoppage"
            },
            {
                "id": 24,
                "type": -1,
                "data": 3782
            },
            {
                "id": 24,
                "type": 4,
                "data": 3
            },
            {
                "id": 24,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 24,
                "type": 2,
                "data": "አዋሽ - አሳብ አውራጐዳና, Dire Dawa, Ethiopia"
            },
            {
                "id": 24,
                "type": 2,
                "data": "2023-11-13 17:33:06"
            },
            {
                "id": 24,
                "type": 2,
                "data": "2023-11-13 19:00:00"
            },
            {
                "id": 24,
                "type": 2,
                "data": "0:00:00"
            },
            {
                "id": 25,
                "type": -1,
                "data": 3782
            },
            {
                "id": 25,
                "type": 4,
                "data": 3
            },
            {
                "id": 25,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 25,
                "type": 2,
                "data": "ጂጂጋ Jijiga, Somali Region, Ethiopia"
            },
            {
                "id": 25,
                "type": 2,
                "data": "2023-11-14 06:57:06"
            },
            {
                "id": 25,
                "type": 2,
                "data": "2023-11-14 07:10:16"
            },
            {
                "id": 25,
                "type": 2,
                "data": "6:29:57"
            },
            {
                "id": 26,
                "type": -1,
                "data": 3782
            },
            {
                "id": 26,
                "type": 4,
                "data": 3
            },
            {
                "id": 26,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 26,
                "type": 2,
                "data": "ጂጂጋ Jijiga, Somali Region, Ethiopia"
            },
            {
                "id": 26,
                "type": 2,
                "data": "2023-11-14 13:40:13"
            },
            {
                "id": 26,
                "type": 2,
                "data": "2023-11-14 13:51:53"
            },
            {
                "id": 26,
                "type": 2,
                "data": "1:24:55"
            },
            {
                "id": 27,
                "type": -1,
                "data": 3782
            },
            {
                "id": 27,
                "type": 4,
                "data": 3
            },
            {
                "id": 27,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 27,
                "type": 2,
                "data": "ጂጂጋ Jijiga, Somali Region, Ethiopia"
            },
            {
                "id": 27,
                "type": 2,
                "data": "2023-11-14 15:16:48"
            },
            {
                "id": 27,
                "type": 2,
                "data": "2023-11-14 15:35:30"
            },
            {
                "id": 27,
                "type": 2,
                "data": "1:40:44"
            },
            {
                "id": 28,
                "type": -1,
                "data": 3782
            },
            {
                "id": 28,
                "type": 4,
                "data": 3
            },
            {
                "id": 28,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 28,
                "type": 2,
                "data": "ጂጂጋ Jijiga, Somali Region, Ethiopia"
            },
            {
                "id": 28,
                "type": 2,
                "data": "2023-11-14 17:16:14"
            },
            {
                "id": 28,
                "type": 2,
                "data": "2023-11-14 17:20:21"
            },
            {
                "id": 28,
                "type": 2,
                "data": "1:29:11"
            },
            {
                "id": 29,
                "type": -1,
                "data": 3782
            },
            {
                "id": 29,
                "type": 4,
                "data": 3
            },
            {
                "id": 29,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 29,
                "type": 2,
                "data": "4, Somali Region, Ethiopia, 3.92 km from ጂጂጋ Jijiga"
            },
            {
                "id": 29,
                "type": 2,
                "data": "2023-11-15 08:47:09"
            },
            {
                "id": 29,
                "type": 2,
                "data": "2023-11-15 09:24:36"
            },
            {
                "id": 29,
                "type": 2,
                "data": "2:32:18"
            },
            {
                "id": 30,
                "type": -1,
                "data": 3782
            },
            {
                "id": 30,
                "type": 4,
                "data": 3
            },
            {
                "id": 30,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 30,
                "type": 2,
                "data": "NCSC DIRA DAWA FACTORY"
            },
            {
                "id": 30,
                "type": 2,
                "data": "2023-11-15 11:56:54"
            },
            {
                "id": 30,
                "type": 2,
                "data": "2023-11-15 17:02:51"
            },
            {
                "id": 30,
                "type": 2,
                "data": "1:33:47"
            },
            {
                "id": 31,
                "type": -1,
                "data": 3782
            },
            {
                "id": 31,
                "type": 4,
                "data": 3
            },
            {
                "id": 31,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 31,
                "type": 2,
                "data": "NA"
            },
            {
                "id": 31,
                "type": 2,
                "data": "2023-11-16 06:00:00"
            },
            {
                "id": 31,
                "type": 2,
                "data": "2023-11-16 13:14:33"
            },
            {
                "id": 31,
                "type": 2,
                "data": "07:14:33"
            },
            {
                "id": 32,
                "type": 1,
                "data": "Plate #"
            },
            {
                "id": 32,
                "type": 1,
                "data": "3-68242"
            },
            {
                "id": 33,
                "type": 1,
                "data": "Route"
            },
            {
                "id": 33,
                "type": 1,
                "data": "DIRE DAWA (TO) ADAMA"
            },
            {
                "id": 34,
                "type": 1,
                "data": "Total Stoppage"
            },
            {
                "id": 34,
                "type": 1,
                "data": "09:26:36"
            },
            {
                "id": 35,
                "type": -1,
                "data": "Assignment ID"
            },
            {
                "id": 35,
                "type": -1,
                "data": "Select"
            },
            {
                "id": 35,
                "type": -1,
                "data": "Remove"
            },
            {
                "id": 35,
                "type": 1,
                "data": "Location"
            },
            {
                "id": 35,
                "type": 1,
                "data": "Start Time"
            },
            {
                "id": 35,
                "type": 1,
                "data": "End Time"
            },
            {
                "id": 35,
                "type": 1,
                "data": "Stoppage"
            },
            {
                "id": 36,
                "type": -1,
                "data": 3773
            },
            {
                "id": 36,
                "type": 4,
                "data": 5
            },
            {
                "id": 36,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 36,
                "type": 2,
                "data": "NCSC ADAMA KOKA GARAGE"
            },
            {
                "id": 36,
                "type": 2,
                "data": "2023-11-14 07:58:18"
            },
            {
                "id": 36,
                "type": 2,
                "data": "2023-11-14 09:02:15"
            },
            {
                "id": 36,
                "type": 2,
                "data": "0:32:40"
            },
            {
                "id": 37,
                "type": -1,
                "data": 3773
            },
            {
                "id": 37,
                "type": 4,
                "data": 5
            },
            {
                "id": 37,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 37,
                "type": 2,
                "data": "NCSC ADAMA KOKA GARAGE"
            },
            {
                "id": 37,
                "type": 2,
                "data": "2023-11-14 09:34:55"
            },
            {
                "id": 37,
                "type": 2,
                "data": "2023-11-14 09:37:07"
            },
            {
                "id": 37,
                "type": 2,
                "data": "8:24:44"
            },
            {
                "id": 38,
                "type": -1,
                "data": 3773
            },
            {
                "id": 38,
                "type": 4,
                "data": 5
            },
            {
                "id": 38,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 38,
                "type": 2,
                "data": "NCSC ADAMA KOKA GARAGE"
            },
            {
                "id": 38,
                "type": 2,
                "data": "2023-11-15 15:31:55"
            },
            {
                "id": 38,
                "type": 2,
                "data": "2023-11-15 15:38:47"
            },
            {
                "id": 38,
                "type": 2,
                "data": "0:03:11"
            },
            {
                "id": 39,
                "type": -1,
                "data": 3773
            },
            {
                "id": 39,
                "type": 4,
                "data": 5
            },
            {
                "id": 39,
                "type": 3,
                "data": "Remove"
            },
            {
                "id": 39,
                "type": 2,
                "data": "Colonel Abdisa Aga Street, Oromia Region, Ethiopia, 5.04 km from Adama"
            },
            {
                "id": 39,
                "type": 2,
                "data": "2023-11-16 11:14:26"
            },
            {
                "id": 39,
                "type": 2,
                "data": "2023-11-16 12:48:32"
            },
            {
                "id": 39,
                "type": 2,
                "data": "0:26:01"
            }
        ]
        this.setState({
            rows_1: rows_1,
            rows_2: rows_2,
        })
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    fetchdata2 = async () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_reports_for_follow_up_stoppage;
        let msg_status = this.state.msg_status
        this.setState({
            isexe: true,
        })
        msg_status = msg_status + "Total of "+this.state.datas.length+" vehicle found. \n"
        this.setState({
            msg_status: msg_status,
        })
        let len=this.state.datas.length
        for (let i = 0; i < len; i++) {
            console.log(this.state.datas[i])
            msg_status = msg_status + "Executing "+(i+1)+" of "+len+"\n"
            this.setState({
                msg_status: msg_status,
            })
            msg_status = msg_status + "Executing vehicle " + this.state.datas[i].plate_no + "....\n"
            this.setState({
                msg_status: msg_status,
            })
            await axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                eid: this.state.eid,
                i: this.state.count,
                st: this.state.datas[i].st,
                ed: this.state.datas[i].ed,
                rr: this.state.datas[i].rou,
                assign_own_id: this.state.datas[i].assign_own_id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    let rows_1 = this.state.rows_1
                    let rows_2 = this.state.rows_2
                    res.data.rows_1.map(v=>{
                        rows_1.push(
                            {
                                id:v.id
                            }
                        )
                    })
                    res.data.rows_2.map(v=>{
                        rows_2.push(
                            {
                                id:v.id,
                                type:v.type,
                                data:v.data,
                            }
                        )
                    })
                    res.data.rows_1.push()
                    msg_status = msg_status + "Execute finished for vehicle " + this.state.datas[i].plate_no + "\n"
                    this.setState({
                        rows_1: rows_1,
                        rows_2: rows_2,
                        msg_status: msg_status,
                        eid: res.data.eid,
                        count: res.data.i,
                    })
                    console.log(rows_1);
                    console.log(rows_2);
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
        this.setState({
            isexe: false,
        })
    }

    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgt_select_template = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            remark: value,
        })
    }
    btncreate = (event) => {
        event.preventDefault()
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.add_remark_follow_up_stoppage;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            rows_2: this.state.rows_2,
            selected_datas: this.state.selected_datas,
            remark: this.state.remark,
            is_vio: this.state.is_vio,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz:1,
                    rows_2: res.data.rows_2,
                    selected_datas: [],
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    wiz_1 = () => {
        let tdata = this.state.rows_1.map(v => {
            let temps = this.state.rows_2.filter(v2 => {
                if (v2.id === v.id)
                    return v2;
            })
            let rows = temps.map(v3 => {
                if (v3.type == 1)
                    return (<th>{v3.data}</th>)
                else if (v3.type == 3)
                    return (<td><a href="" onClick={event => {
                        event.preventDefault();
                        let rows_2=this.state.rows_2.filter(v=>{
                            if(v.id!=v3.id)
                                return v;
                        })
                        this.setState({
                            rows_2:rows_2
                        })
                    }}>{v3.data}</a></td>)
                else if (v3.type == 4)
                    return (<td><input className="form-control-sm form-check" type="checkbox" onChange={event=>{
                        let selected_datas=this.state.selected_datas;
                        if(event.target.checked)
                            selected_datas.push({
                                id:v3.id,
                            })
                        else
                            selected_datas=selected_datas.filter(v0=>{
                                if(v0.id!=v3.id)
                                    return v0;
                            })
                        this.setState({
                            selected_datas:selected_datas,
                        },()=>{
                            console.log(selected_datas)
                            console.log(this.state.rows_2)
                        })
                    }}></input>
                        <a href="" onClick={event => {
                            event.preventDefault();
                            if(this.state.selected_datas.length==0){
                                Swal.fire({
                                    title: 'Error!',
                                    text: "At least one data must be selected",
                                    icon: 'error',
                                    confirmButtonText: 'Okay'
                                })
                            }
                            else
                            {
                                this.setState({
                                    wiz:2,
                                    remark_template:"",
                                    remark:'',
                                    is_vio:false,
                                })
                            }
                        }}> Add</a>
                    </td>)
                else if (v3.type ==2)
                    return (<td>{v3.data}</td>)
                else if (v3.type ==2.1)
                    return (<td>{v3.data}</td>)
                else if (v3.type ==2.2)
                    return (<td style={{backgroundColor:"red",color:"white"}}>{v3.data}</td>)
                else if (v3.type ==2.3)
                    return (<td style={{backgroundColor:"green",color:"white"}}>{v3.data}</td>)
                else
                    return null;
            })
            return (<tr>
                {rows}
            </tr>)
        })
        return (<>
            <div className="col-md-4  px-3 py-1">
                <div className="form-group">
                    <label>Information Status</label>
                    <textarea rows={7} className="form-control form-control-sm" name="msg_status"
                              value={this.state.msg_status} onChange={this.inputmgt}></textarea>
                </div>
            </div>
            <div className="col-md-8">
            </div>
            <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                           onClick={this.btnexport_report}>Download .xlsx</a></div>
            <div className="col-12 px-3 py-1">
                <div className="table" id="section_to_print">
                    <table id="report" className="table table-sm table-bordered table-responsive">
                        {tdata}
                    </table>
                </div>
            </div>
        </>)
    }

    wiz_2 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header"
                             style={{backgroundColor: myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Remark Management</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Remark Template</label>
                                        <select className="form-control form-control-sm" name="remark_template"
                                                value={this.state.remark_template} onChange={this.inputmgt_select_template}>
                                            <option value="">--Select Remark Template--</option>
                                            <option value="Stops for Breakfast">Stops for Breakfast</option>
                                            <option value="Stops for Lunch">Stops for Lunch</option>
                                            <option value="Stops for rest">Stops for rest</option>
                                            <option value="Road Call">Road Call</option>
                                            <option value="Garage">Garage</option>
                                            <option value="Driver Phone is switched off">Driver Phone is switched off</option>
                                            <option value="Driver stoppage">Driver stoppage</option>
                                            <option value="Driver stoppage">Not exceed stoppage violation</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea rows={10} type="text" className="form-control form-control-sm" name="remark"
                                                  value={this.state.remark} onChange={this.inputmgt}
                                                  placeholder="Remark"></textarea>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Is Violation</label>
                                        <input type="checkbox" checked={this.state.is_vio} className="form-control form-control-sm" name="is_vio"
                                               onChange={event => {
                                                   this.setState({
                                                       is_vio:event.target.checked
                                                   })
                                               }}/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}
                                                onClick={this.btncreate}> Add Remark </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}
                                                onClick={event => {
                                                    event.preventDefault();
                                                    this.setState({
                                                        wiz:1,
                                                        selected_datas: [],
                                                    })
                                                }}> Cancel
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="follow_up_stoppage"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Follow-up Stoppage</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {dis}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Follow_up_stoppage;
