import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Tg_status_single extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filters: [],
            opts: [],
            wiz:1,
            status_type:"All",
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_message_for_tg_status_single;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            }
            else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
            else {
                this.setState({
                    datas: res.data.datas,
                    filters: res.data.datas,
                    opts: res.data.opts,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            status: value,
        })
    }
    fil=(value)=>{
        let filters = this.state.datas;
        if (value !== "All")
            filters = filters.filter(v => {
                if (v.status === value)
                    return v;
            })
        this.setState({
            status: value,
            filters: filters,
        })
    }
    btnsend = (event) => {
        event.preventDefault();
        if(this.state.status_type==="All"){
            Swal.fire({
                title: 'Error!',
                text: "All Status can not be send at time",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.send_message_for_tg_status_single;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                status_type:this.state.status_type,
                datas:this.state.filters,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btnsend_sum = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.send_message_for_tg_status_single_sum;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            datas:this.state.filters,
            all_datas:this.state.datas,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let count = 0;
        let tdata = this.state.filters.map(value => {
            count = count + 1;
            return (<tr>
                <td>{count}</td>
                <td><a href="" onClick={event => {
                    event.preventDefault();
                    let datas = this.state.datas.filter(v => {
                        if (v.vehicle_id !== value.vehicle_id)
                            return v;
                    })
                    let filters = this.state.filters.filter(v => {
                        if (v.vehicle_id !== value.vehicle_id)
                            return v;
                    })
                    this.setState({
                        datas: datas,
                        filters: filters,
                    })

                }}>Remove</a></td>
                <td>{value.plate_no}</td>
                <td>
                    <a href="" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            vehicle_id:value.vehicle_id,
                            status:value.status,
                            plate_no:value.plate_no,
                            wiz:2,
                        })
                    }}>
                        {value.status}
                    </a>
                </td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-2">#</th>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-4">Plate #</th>
                            <th className="col-md-4">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn float-right m-2"
                        style={{backgroundColor: myconfig.color_2, color: myconfig.color_3}}
                        onClick={this.btnsend}>
                    Send Detail Message
                </button>
                <button type="button" className="btn float-right m-2"
                        style={{backgroundColor: myconfig.color_1, color: myconfig.color_3}}
                        onClick={this.btnsend_sum}>
                    Send Summary Message
                </button>
            </div>
        </>)
    }
    wiz_2 = () => {
        let opts=this.state.opts.map(v=>{
            return(<option value={v.status}>{v.status}</option>)
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Edit Data</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.plate_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <select className="form-control form-control-sm"
                                                value={this.state.status} name="status"onChange={this.inputmgt}>
                                            {opts}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm" style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                                                onClick={event => {
                                                    event.preventDefault()
                                                    let datas = this.state.datas.map(v2 => {
                                                        if (v2.vehicle_id === this.state.vehicle_id) {
                                                            v2.status = this.state.status
                                                        }
                                                        return v2;
                                                    })
                                                    let filters = this.state.filters.map(v2 => {
                                                        if (v2.vehicle_id === this.state.vehicle_id) {
                                                            v2.status = this.state.status
                                                        }
                                                        return v2;
                                                    })

                                                    this.setState({
                                                        datas: datas,
                                                        filters: filters,
                                                        wiz:1,
                                                    },()=>{
                                                        this.fil(this.state.status_type)
                                                    })
                                                }}> Edit </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}  onClick={event => {
                                            event.preventDefault();
                                            this.setState({
                                                wiz:1,
                                            })
                                        }}> Cancel
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        let opts=this.state.opts.map(v=>{
            return (<option value={v.status}>{v.status}</option> )
        })
        return (
            <>
                <Nav type="tg_status_single"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Telegram Status Single</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '250px'}}>
                                                    <select name="status_type"
                                                            className="form-control float-right"
                                                            value={this.state.status_type} onChange={event => {
                                                        event.preventDefault();
                                                        var value = event.target.value;
                                                        this.setState({
                                                            status_type:value,
                                                        },()=>{
                                                            this.fil(value)
                                                        })

                                                    }}>
                                                        <option value="All">All</option>
                                                        {opts}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Tg_status_single;
