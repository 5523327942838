import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Tg_status_det extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            wiz:1,
            isexe:false,
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_message_for_tg_status_det;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                }, () => {
                    this.fetchdata2();
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata2 = async () => {
        if (!this.state.isexe) {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_avl_for_tg_status_det;
            this.setState({
                isexe: true,
            })
            let len = this.state.datas.length
            for (let i = 0; i < len; i++) {
                await axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    eid: this.state.eid,
                    vehicle_id: this.state.datas[i].vehicle_id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        let datas = this.state.datas.map(v2 => {
                            if (v2.vehicle_id === this.state.datas[i].vehicle_id) {
                                v2.location = res.data.location
                                v2.update_time = res.data.update_time
                                v2.update_time_day = res.data.update_time_day
                                v2.is_gps_working = res.data.is_gps_working
                            }
                            return v2;
                        })
                        this.setState({
                            datas: datas,
                            eid: res.data.eid,
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
            this.setState({
                isexe: false,
            })
        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnsend = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.send_message_for_tg_status_det;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            datas:this.state.datas,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.datas.map(value => {
            count=count+1;
            let colr="green";
            if(!value.is_gps_working)
                colr="red";
            return (<tr>
                <td><a href="" onClick={event => {
                    event.preventDefault();
                    if(this.state.isexe){
                        Swal.fire({
                            title: 'Error!',
                            text: "You can not remove at this time. But you can edit the data",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    }
                    else{
                        let datas = this.state.datas.filter(v2 => {
                            if (v2.vehicle_id !== value.vehicle_id)
                                return v2;
                        })
                        this.setState({
                            datas:datas
                        })
                    }
                }}>Remove</a></td>
                <td style={{backgroundColor:colr,color:"white"}}>{value.plate_no}</td>
                <td>
                    <a href="" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            is_gps_working:value.is_gps_working,
                            location:value.location,
                            update_time:value.update_time,
                            update_time_day:value.update_time_day,
                            vehicle_id:value.vehicle_id,
                            plate_no:value.plate_no,
                            wiz:2,
                        })
                    }}>
                    {value.location}
                    </a>
                </td>
                <td style={{backgroundColor:colr,color:"white"}}>{value.update_time_day}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-3">Plate No</th>
                            <th className="col-md-4">Location</th>
                            <th className="col-md-3">Update Time</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn float-right"
                        style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}
                        onClick={this.btnsend}>
                    Send Message
                </button>
            </div>
        </>)
    }
    wiz_2 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Edit Data</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.plate_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Location</label>
                                        <textarea rows={5} className="form-control form-control-sm" name="location"
                                               value={this.state.location} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Update Time</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.update_time} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Is GPS Working</label>
                                        <input type="checkbox" className="form-control form-control-sm" name="is_gps_working"
                                               checked={this.state.is_gps_working} onChange={event => {
                                                   this.setState({
                                                       is_gps_working:event.target.checked,
                                                   })
                                        }}/>
                                    </div>
                                </div>
                                <div className="col-md-8"></div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm" style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                                                onClick={event => {
                                                    event.preventDefault()
                                                    let datas = this.state.datas.map(v2 => {
                                                        if (v2.vehicle_id === this.state.vehicle_id) {
                                                            v2.location = this.state.location
                                                            v2.update_time = this.state.update_time
                                                            v2.update_time_day = this.state.update_time_day
                                                            v2.is_gps_working = this.state.is_gps_working
                                                        }
                                                        return v2;
                                                    })
                                                    this.setState({
                                                        datas: datas,
                                                        wiz:1,
                                                    })
                                                }}> Edit </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}  onClick={event => {
                                                    event.preventDefault();
                                                    this.setState({
                                                        wiz:1,
                                                    })
                                        }}> Cancel
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="tg_status_det"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Telegram Status Detail</h3>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Tg_status_det;
