import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";
import nt from "../../img/ntlog.png";
import style from "../../style2.css"

let source = axios.CancelToken.source();

class Order_open extends Component {

    constructor() {
        super();
        this.state = {
            data:{},
            is_exe:false,
            btn_text:"Create",
            no_img:1,
            imgs:[],
            datas: [],
            txtsearch: '',
            clients: [],
            clients_main: [],
            cargos: [],
            locations: [],
            cements: [],

            transport_type: "TPT",
            wiz: 1,
            client_selected: null,
            cargo_selected: null,
            org_selected: null,
            des_selected: null,
            cement_selected: null,
            iscreate: true,
            id: -1,
            isdoc: 1,
            des: '',
            weight: '',
            mode_of_transport: 'Uni Modal',
            company_name: '',
            client_type: '',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
            millage: 'NA',
            transport_rate: '',
            st_date: '',
            ed_date: '',
            bank_tt_dis:false,
            tpt_dis:false,
            payment_type:'-1',
            bank_tt:'',
            agreement_no:'',
            is_sis:-1,
        }
    }

    btnsubmit = (event) => {
        event.preventDefault()
        // Create an object of formData
        console.log(this.state.imgs)
        const formData = new FormData();

        // Update the formData object
        let len=this.state.imgs.length
        formData.append(
            "img_length",
            len,
        );
        for(let i=1; i<=len;i++) {
            formData.append(
                "img_" + i.toString(),
                this.state.imgs[i-1].img,
            );
        }
        formData.append(
            "sid",window.localStorage.getItem("logmgtsys_sid")
        );
        formData.append(
            "st",this.state.st
        );
        formData.append(
            "ed",this.state.ed
        );
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.open_order;
        this.setState({
            reports: [],
        })
        axios.post(baseurl, formData, {
            cancelToken: source.token,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: "Report fetched successfully",
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    reports: res.data.reports,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    onfilechange = (event,id) => {
        event.preventDefault()
        if(event.target.files[0]!==undefined){
            let isfound=false;
            let imgs = this.state.imgs.filter(v=>{
                if(v.name==="img_" + id){
                    isfound=true;
                    v.img=event.target.files[0]
                }
                return v;
            })
            if(!isfound)
                imgs.push({
                    name: "img_" + id,
                    img: event.target.files[0],
                })
            this.setState({
                imgs: imgs,
            });
        }

    }


    handelchange_client = (client_selected) => {
        this.setState({
            client_selected:client_selected,
            company_name: '',
            client_type:'',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
            is_sis:-1
        },()=>{
            let temp=this.state.clients_main.filter(v=>{
                console.log(client_selected.value)
                console.log(v.id)
                if(v.id===client_selected.value)
                    return v;
            })
            if(temp.length>0) {
                this.setState({
                    company_name: temp[0].company_name==null?"NA":temp[0].company_name,
                    client_type: temp[0].type==null?"NA":temp[0].type,
                    contact_person: temp[0].contact_person==null?"NA":temp[0].contact_person,
                    tin: temp[0].tin==null?"NA":temp[0].tin,
                    city: temp[0].city==null?"NA":temp[0].city,
                    address: temp[0].address==null?"NA":temp[0].address,
                    phone: temp[0].phone==null?"NA":temp[0].phone,
                    email: temp[0].email==null?"NA":temp[0].email,
                    is_sis: temp[0].is_sis==null?"NA":temp[0].is_sis,
                })
            }
        })
    }
    handelchange_cargo = (cargo_selected) => {
        this.setState({
            cargo_selected:cargo_selected,
        })
    }
    handelchange_cement = (cement_selected) => {
        let temp = this.state.cements.filter(v => {
            if (v.value === cement_selected.value)
                return v;
        })
        if (temp.length > 0) {
            this.setState({
                purchase_no: temp[0].purchase_no,
                cargo: temp[0].cargo,
                order_date: temp[0].order_date,
                client_selected:{value:temp[0].client_id,label:temp[0].client},
                cargo_selected:{value:temp[0].cargo_id,label:temp[0].cargo},
                client_type:temp[0].client_type,
                company_name:temp[0].client_obj.company_name,
                contact_person:temp[0].client_obj.contact_person,
                city:temp[0].client_obj.city,
                address:temp[0].client_obj.address,
                phone:temp[0].client_obj.phone,
                email:temp[0].client_obj.email,
            })
        }
        this.setState({
            cement_selected:cement_selected,
        })
    }

    handelchange_orgin = (org_selected) => {
        if (!this.state.isexecuting) {
            if (this.state.des_selected != null) {
                this.setState({
                    org_selected: org_selected,
                },()=>this.check_route())

            } else {
                this.setState({
                    org_selected: org_selected,
                    isexecuting: false,
                })
            }
        }
    }
    handelchange_des = (des_selected) => {
        if (!this.state.isexecuting) {
            if (this.state.org_selected != null) {
                this.setState({
                    des_selected: des_selected,
                },()=>this.check_route())
            } else {
                this.setState({
                    des_selected: des_selected,
                    isexecuting: false,
                })
            }
        }
    }
    check_route = () => {
        if(this.state.org_selected!=null && this.state.des_selected!=null){
            this.setState({
                isexecuting: true,
            },()=>{
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.check_route;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    org: this.state.org_selected.value,
                    des: this.state.des_selected.value,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        this.setState({
                            millage:res.data.millage,
                            isexecuting: false,
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            })
        }
        else
        {
            this.setState({
                isexecuting: false,
            })
        }
    }
    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_open_order_for_open_order;
        this.setState({
            datas: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    clients: res.data.clients,
                    cargos: res.data.cargos,
                    clients_main: res.data.clients_main,
                    cocs: res.data.cocs,
                    locations: res.data.locations,
                    cements: res.data.cements,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.company_name.toUpperCase().includes(value.toUpperCase()
                    )
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgt_payment_type = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let bank_tt_dis=false
        if(value==="cash")
            bank_tt_dis=true;
        this.setState({
            [name]: value,
            bank_tt_dis: bank_tt_dis,
        })
    }
    inputmgt_transport_type = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let tpt_dis=false
        this.setState({
            [name]: value,
            tpt_dis: tpt_dis,
            purchase_no:'',
            cargo:'',
            order_date:'',
            cement_selected: null,
        })
    }
    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            btn_text:"Create",
            wiz: 2,
            cargo_selected: null,
            org_selected: null,
            des_selected: null,
            client_selected: null,
            iscreate: true,
            id:-1,
            des:'',
            weight:'',
            company_name: '',
            client_type:'',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
            millage: 'NA',
            container_no:'',
            transport_rate:'',
            st_date:'',
            ed_date:'',
            purchase_no:'',
            payment_type:'-1',
            bank_tt:'',
            agreement_no:'',
        })
    }

    btnshow = (event,id) => {
        event.preventDefault();
        this.getorder(id,4)
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            cargo_selected: null,
            org_selected: null,
            des_selected: null,
            client_selected: null,
            iscreate: true,
            id:-1,
            des:'',
            weight:'',
            company_name: '',
            client_type:'',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
            millage: 'NA',
            container_no:'',
            volume:'',
            transport_rate:'',
            st_date:'',
            ed_date:'',
        })
    }
    btnback = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        if (this.state.client_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select client.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.org_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select origin.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.des_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select destination.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.millage === "NA") {
            Swal.fire({
                title: 'Error!',
                text: "Root is not found",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.cargo_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select cargo.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.mode_of_transport !== "Uni Modal" && this.state.mode_of_transport !== "Multi Modal") {
            Swal.fire({
                title: 'Error!',
                text: "Select mode of transport",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.is_sis === 1) {
            Swal.fire({
                title: 'Selected client are sister company. The order will be approved automatically. Are you sure you want to open the order? You can not undone the operation',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.create()
                }
            })
        }
        else {
            this.create()
        }
    }
    btncreate_withcontainer = (event) => {
        event.preventDefault();
        let temp = [];
        for (let i = 1; i <= this.state.container_no; i++) {
            temp.push({
                container: this.state["container_" + i],
                cost: this.state["cost_" + i],
                size: this.state["size_" + i],
                volume: this.state["volume_" + i],
                price: this.state["price_" + i],
            })
        }
        this.setState({
            containers: temp,
        }, () => this.create())
    }
    create=()=>{
        if(!this.state.is_exe) {
            this.setState({
                is_exe:true,
                btn_text:"Working...",
            })
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.open_order;
            let cement_selected=this.state.cement_selected==null?null:this.state.cement_selected.value;
            const formData = new FormData();
            formData.append(
                "sid",window.localStorage.getItem("logmgtsys_sid")
            );
            formData.append(
                "cement_id",cement_selected
            );
            formData.append(
                "client_id",this.state.client_selected.value
            );
            formData.append(
                "transport_type","TPT"
            );
            formData.append(
                "org_id",this.state.org_selected.value
            );
            formData.append(
                "des_id",this.state.des_selected.value
            );
            formData.append(
                "des",this.state.des
            );
            formData.append(
                "cargo_id",this.state.cargo_selected.value
            );
            formData.append(
                "tol","normal"
            );
            formData.append(
                "mode_of_transport",this.state.mode_of_transport
            );
            formData.append(
                "no_container",this.state.no_container
            );
            formData.append(
                "weight",this.state.weight
            );
            formData.append(
                "transport_rate",this.state.transport_rate
            );
            formData.append(
                "st_date",this.state.st_date
            );
            formData.append(
                "ed_date",this.state.ed_date
            );
            formData.append(
                "containers",this.state.containers
            );
            formData.append(
                "isdoc",this.state.isdoc
            );
            formData.append(
                "purchase_no",this.state.purchase_no
            );
            formData.append(
                "payment_type",this.state.payment_type
            );
            formData.append(
                "bank_tt",this.state.bank_tt
            );
            formData.append(
                "agreement_no",this.state.agreement_no
            );
            let len=this.state.imgs.length
            formData.append(
                "img_length",
                len,
            );
            for(let i=1; i<=len;i++) {
                formData.append(
                    "img_" + i.toString(),
                    this.state.imgs[i-1].img,
                );
            }
            axios.post(baseurl, formData, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    this.setState({
                        is_exe:false,
                        btn_text:"Create",
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                            is_exe:false,
                            btn_text:"Create",
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    update=()=>{
        if(!this.state.is_exe) {
            this.setState({
                is_exe:true,
                btn_text:"Working...",
            })

            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.open_order_edit;
            let cement_selected=this.state.cement_selected==null?null:this.state.cement_selected.value;
            const formData = new FormData();
            formData.append(
                "sid",window.localStorage.getItem("logmgtsys_sid")
            );
            formData.append(
                "cement_id",cement_selected
            );
            formData.append(
                "id",this.state.id
            );
            formData.append(
                "client_id",this.state.client_selected.value
            );
            formData.append(
                "transport_type","TPT"
            );
            formData.append(
                "org_id",this.state.org_selected.value
            );
            formData.append(
                "des_id",this.state.des_selected.value
            );
            formData.append(
                "des",this.state.des
            );
            formData.append(
                "cargo_id",this.state.cargo_selected.value
            );
            formData.append(
                "tol","normal"
            );
            formData.append(
                "mode_of_transport",this.state.mode_of_transport
            );
            formData.append(
                "no_container",this.state.no_container
            );
            formData.append(
                "weight",this.state.weight
            );
            formData.append(
                "transport_rate",this.state.transport_rate
            );
            formData.append(
                "st_date",this.state.st_date
            );
            formData.append(
                "ed_date",this.state.ed_date
            );
            formData.append(
                "containers",this.state.containers
            );
            formData.append(
                "isdoc",this.state.isdoc
            );
            formData.append(
                "purchase_no",this.state.purchase_no
            );
            formData.append(
                "payment_type",this.state.payment_type
            );
            formData.append(
                "bank_tt",this.state.bank_tt
            );
            axios.post(baseurl, formData, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    this.setState({
                        is_exe:false,
                        btn_text:"Edit",
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                            is_exe:false,
                            btn_text:"Create",
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }

    btnupdate = (event) => {
        event.preventDefault();
        if (this.state.client_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select client.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.org_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select origin.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.des_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select destination.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.millage === "NA") {
            Swal.fire({
                title: 'Error!',
                text: "Root is not found",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.cargo_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select cargo.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.mode_of_transport !== "Uni Modal" && this.state.mode_of_transport !== "Multi Modal") {
            Swal.fire({
                title: 'Error!',
                text: "Select mode of transport",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.is_sis === 1) {
            Swal.fire({
                title: 'Selected client are sister company. The order will be approved automatically. Are you sure you want to open the order? You can not undone the operation',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.update()
                }
            })
        }
        else {
            this.update()
        }
    }
    getorder = (id,wiz) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_order;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: wiz,
                    client_selected: {value:res.data.data.client_id,label:res.data.data.company_name},
                    cargo_selected: {value:res.data.data.cargo_id,label:res.data.data.name},
                    org_selected: {value:res.data.data.org_id,label:res.data.data.org},
                    des_selected: {value:res.data.data.destination_id,label:res.data.data.destination},
                    iscreate: false,
                    btn_text:"Edit",
                    id: id,
                    order_no: res.data.data.order_no,
                    des: res.data.data.des,
                    weight: res.data.data.weight,
                    transport_rate: res.data.data.transport_rate,
                    mode_of_transport: res.data.data.mode_of_transport,
                    client_type: res.data.data.type,
                    tol: res.data.data.tol,
                    st_date: res.data.data.st_date,
                    ed_date: res.data.data.ed_date,
                    isdoc: res.data.data.isdoc,
                    purchase_no: res.data.data.purchase_no,
                    company_name: res.data.data.company_name==null?"NA":res.data.data.company_name,
                    contact_person: res.data.data.contact_person==null?"NA":res.data.data.contact_person,
                    tin: res.data.data.tin==null?"NA":res.data.data.tin,
                    city: res.data.data.city==null?"NA":res.data.data.city,
                    address: res.data.data.address==null?"NA":res.data.data.address,
                    phone: res.data.data.phone==null?"NA":res.data.data.phone,
                    email: res.data.data.email==null?"NA":res.data.data.email,
                    millage: res.data.data.millage,
                    container_no: res.data.data.container_no,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_show = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_open_order_for_open_order;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 3,
                    id:id,
                    data:res.data.data,
                    urls:res.data.urls,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_edit = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_open_order_for_edit_for_open_order;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    id: id,
                    iscreate: false,
                    btn_text: "Update",
                    cargo_selected: res.data.data.cargo_selected,
                    org_selected: res.data.data.org_selected,
                    des_selected: res.data.data.des_selected,
                    client_selected: res.data.data.client_selected,
                    des: res.data.data.order.des,
                    weight: res.data.data.order.weight,
                    company_name: res.data.data.client.company_name,
                    client_type: res.data.data.client_type,
                    contact_person: res.data.data.client.contact_person,
                    tin: res.data.data.client.tin,
                    city: res.data.data.client.city,
                    address: res.data.data.client.address,
                    phone: res.data.data.client.phone,
                    email: res.data.data.client.email,
                    millage: res.data.data.millage,
                    transport_rate: res.data.data.order.transport_rate,
                    st_date: res.data.data.order.st_date,
                    ed_date: res.data.data.order.ed_date,
                    payment_type: res.data.data.payment_type,
                    bank_tt: res.data.data.order.bank_tt,
                    transport_type: res.data.data.order.transport_type,
                    bank_tt_dis: res.data.data.bank_tt_dis,
                    tpt_dis: res.data.data.tpt_dis,
                    order_date: res.data.data.order_date,
                    agreement_no: res.data.data.agreement_no,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    btn_delete = (event,id) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to remove this order? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({
                    is_exe:true,
                })
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.open_order_delete;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id: id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        this.setState({
                            is_exe:false,
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz:1,
                                is_exe:false,
                            },()=>{
                                this.fetchdata()
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    btnaddattachment = (event,id) => {
        event.preventDefault();
        this.setState({
            is_create: false,
            id:id,
            wiz: 4,
        });
    }
    b_attached_document = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.order_open_add_attachment;
        const formData = new FormData();
        formData.append(
            "sid", window.localStorage.getItem("logmgtsys_sid")
        );
        formData.append(
            "id", this.state.id
        );
        let len = this.state.imgs.length
        formData.append(
            "img_length",
            len,
        );
        for (let i = 1; i <= len; i++) {
            formData.append(
                "img_" + i.toString(),
                this.state.imgs[i - 1].img,
            );
        }
        axios.post(baseurl, formData, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    is_exe: false,
                    btn_text: "Edit",
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                        is_exe: false,
                        btn_text: "Create",
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnremoveattachment = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_image_for_remove_for_order_open;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 5,
                    urls:res.data.urls,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    btn_delete_attachment = (event,id) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to remove this attachment? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({
                    is_exe:true,
                })
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.order_open_remove_attachment;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id: id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        this.setState({
                            is_exe:false,
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz:1,
                                is_exe:false,
                            },()=>{
                                this.fetchdata()
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    btn_get_aggrement = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_aggrement_obj_for_open_order;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    is_exe:false,
                })
            } else {
                this.setState({
                    wiz:6,
                    data:res.data.data,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let tdata = this.state.datas.map(value => {
            return (<tr>
                <td style={{verticalAlign:"middle"}}>
                    <a href="" onClick={event =>{
                        this.btn_show(event,value.id)
                    }}>Show</a> <hr/>
                    <a href="" onClick={event =>{
                        this.btn_edit(event,value.id)
                    }}>Edit</a> <hr/>
                    <a href="" onClick={event =>{
                        this.btn_get_aggrement(event,value.id)
                    }}>Get Agreement </a> <hr/>
                    <a href="" onClick={event =>{
                        this.btnaddattachment(event,value.id)
                    }}>Add Attachment</a> <hr/>
                    <a href="" onClick={event =>{
                        this.btnremoveattachment(event,value.id)
                    }}>Remove Attachment</a> <hr/>
                    <a href="" onClick={event =>{
                        this.btn_delete(event,value.id)
                    }}>Remove</a> <hr/>
                </td>
                <td style={{verticalAlign:"middle"}}>{value.order_no}</td>
                <td style={{verticalAlign:"middle"}}>{value.client}</td>
                <td style={{verticalAlign:"middle"}}>{value.org}</td>
                <td style={{verticalAlign:"middle"}}>{value.des}</td>
                <td style={{verticalAlign:"middle"}}>{value.cargo}</td>
                <td style={{verticalAlign:"middle"}}>{value.transport_rate}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-outline-success btn-block" onClick={this.btnnew}>New
                        Order</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-1">Order #</th>
                            <th className="col-md-2">Client</th>
                            <th className="col-md-2">Origin</th>
                            <th className="col-md-2">Destination</th>
                            <th className="col-md-2">Cargo</th>
                            <th className="col-md-2">Transport Rate</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let files=null;
        let btn=<button className="btn btn-block btn-outline-success btn-sm" onClick={this.btnupdate}> {this.state.btn_text} </button>
        if(this.state.iscreate){
            btn=<button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> {this.state.btn_text} </button>
            let no_img=[];
            for(let i=1;i<=this.state.no_img;i++){
                no_img.push(i)
            }
            let t=no_img.map(v=>{
                return(
                    <div className="col-md-12">
                        <input className="m-2" type="file" onChange={event => {this.onfilechange(event,v)}}/>
                    </div>
                )
            });
            files=<>
                <div className="col-md-6">
                    <label htmlFor="formFileSm" className="form-label">Agreements</label><p>(Agreement and other file must be in image format)</p>
                    <a href="" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            no_img:this.state.no_img+1,
                        })
                    }}>(Add Additional File) <br/></a>
                </div>
                <div className="col-md-12">
                </div>
                {t}
            </>
        }

        let bank_tt_dis=null;
        if(this.state.bank_tt_dis)
            bank_tt_dis= <div className="form-group">
                <label>Bank Deposit #</label>
                <input type="text" className="form-control form-control-sm" name="bank_tt"
                       value={this.state.bank_tt} onChange={this.inputmgt} placeholder="Bank Deposit #" />
            </div>

        let tpt_dis=null;
        let is_disabled=false;
        if(this.state.transport_type==="TPT+CMT")
            is_disabled=true;
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Order Management</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {/*   <div className="col-md-3">
                                    <div className="form-group">
                                        <label></label>
                                        <a href="" onClick={this.btn_get_aggrement}>Get Agreement Here</a>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                </div>*/}
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Client</label>
                                        <Select
                                            isDisabled={is_disabled}
                                            value={this.state.client_selected}
                                            onChange={this.handelchange_client}
                                            options={this.state.clients}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Client Type</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="client_type"
                                               value={this.state.client_type} onChange={this.inputmgt}
                                               placeholder="Client Type"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="company_name"
                                               value={this.state.company_name} onChange={this.inputmgt}
                                               placeholder="Company name"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div disabled={true} className="form-group">
                                        <label>Contact person</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="contact_person" value={this.state.contact_person}
                                               onChange={this.inputmgt} placeholder="Contact person"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>TIN</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="tin"
                                               value={this.state.tin} onChange={this.inputmgt} placeholder="TIN"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>City</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="city"
                                               value={this.state.city} onChange={this.inputmgt} placeholder="City"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="address"
                                               value={this.state.address} onChange={this.inputmgt}
                                               placeholder="Address"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Phone #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="phone"
                                               value={this.state.phone} onChange={this.inputmgt} placeholder="Phone #"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input disabled={true} type="email" className="form-control form-control-sm" name="email"
                                               value={this.state.email} onChange={this.inputmgt} placeholder="Email"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Orgin</label>
                                        <Select
                                            value={this.state.org_selected}
                                            onChange={this.handelchange_orgin}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <Select
                                            value={this.state.des_selected}
                                            onChange={this.handelchange_des}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="millage"
                                               value={this.state.millage} onChange={this.inputmgt} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Description of Good</label>
                                        <textarea rows={5} className="form-control form-control-sm" name="des"
                                                  onChange={this.inputmgt} placeholder="Description of Good">{this.state.des}</textarea>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cargo</label>

                                        <Select
                                            isDisabled={is_disabled}
                                            value={this.state.cargo_selected}
                                            onChange={this.handelchange_cargo}
                                            options={this.state.cargos}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Start Time</label>
                                        <input type="date" className="form-control form-control-sm" name="st_date"
                                               value={this.state.st_date} onChange={this.inputmgt} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>End Date</label>
                                        <input type="date" className="form-control form-control-sm" name="ed_date"
                                               value={this.state.ed_date} onChange={this.inputmgt} />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Volume</label>
                                        <input type="text" className="form-control form-control-sm" name="weight"
                                               value={this.state.weight} onChange={this.inputmgt}
                                               placeholder="Volume"/>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Transport Rate</label>
                                        <input type="text" className="form-control form-control-sm" name="transport_rate"
                                               value={this.state.transport_rate} onChange={this.inputmgt}
                                               placeholder="Transport Rate"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Payment Type</label>
                                        <select className="form-control form-control-sm" name="payment_type"
                                                value={this.state.payment_type} onChange={this.inputmgt_payment_type}>
                                            <option value="-1">-- Select Payment Type--</option>
                                            <option value="cash">Cash</option>
                                            <option value="credit">Credit</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    {bank_tt_dis}
                                </div>
                                {/* <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Agreement #</label>
                                        <input type="text" className="form-control form-control-sm" name="agreement_no"
                                               value={this.state.agreement_no} onChange={this.inputmgt}
                                               placeholder="Agreement #"/>
                                    </div>
                                </div>
                                <div className="col-md-9"></div>*/}
                                {tpt_dis}
                                {files}
                                <div className="col-md-3 my-3">
                                    <div className="form-group">
                                        {btn}
                                    </div>
                                </div>
                                <div className="col-md-3 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        let len=this.state.len;
        let temp=[];
        for(let i=1; i<=len;i++){
            let va="img_"+i.toString();
            temp.push({
                state_name:va
            })
        }
        let imgs=this.state.urls.map(v=>{
            let sty="rotate("+v.deg+"deg)"
            return (<>
                    <div className="col-md-12 text-center my-2 border">
                        <img style={{transform:sty}} src={v.url}/>
                    </div>
                    <div className="col-md-12 text-center my-2 border">
                        <a href="" onClick={event => {
                            event.preventDefault()
                            let urls=this.state.urls.map(v2=>{
                                if(v2.id===v.id){
                                    v2.deg=v2.deg+90
                                    if(v2.deg>270)
                                        v2.deg=0
                                }
                                return v2
                            });
                            this.setState({
                                urls:urls
                            })
                        }}>Image Rotate</a>
                    </div>
                </>
            )
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Order</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.order_no}/>
                                    </div>
                                </div>
                                <div className="col-md-9">

                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.client}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Origin</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.distance}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Description of Good</label>
                                        <textarea rows={5} className="form-control form-control-sm"
                                                  onChange={this.inputmgt} readOnly={true}>{this.state.data.des_good}</textarea>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Modal Of Transport</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.mode_of_transport}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>PO #</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.purchase_no}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Start Time</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.st_date}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>End Date</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.ed_date}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Transport Rate</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.transport_rate}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Weight (q)</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.weight}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Payment Type</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.payment_type}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Bank Deposit #</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.bank_tt}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Agreement #</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.agreement_no}/>
                                    </div>
                                </div>
                                {imgs}
                                <div className="col-md-4 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:1,
                                                    })
                                                }}> Close
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-8 my-3">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_4 = () => {
        let no_img = [];
        for (let i = 1; i <= this.state.no_img; i++) {
            no_img.push(i)
        }
        let t = no_img.map(v => {
            return (
                <div className="col-md-12">
                    <input className="m-2" type="file" onChange={event => {
                        this.onfilechange(event, v)
                    }}/>
                </div>
            )
        });
        let files =<>
            <div className="col-md-6">
                <label htmlFor="formFileSm" className="form-label">Agreements</label><p>(Agreement and other
                file must be in image format)</p>
                <a href="" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        no_img: this.state.no_img + 1,
                    })
                }}>(Add Additional File) <br/></a>
            </div>
            <div className="col-md-6">
            </div>
            {t}
        </>
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Cement Order Add Attachment</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {files}
                                <div className="col-md-4 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-primary btn-sm"
                                                onClick={this.b_attached_document}> Add Attachment
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_5 = () => {
        let imgs=this.state.urls.map(v=>{
            return (<>
                <div className="col-md-12 text-center my-2 border">
                    <img src={v.url}/>
                </div>
                <div className="col-md-12 text-center my-2 border">
                    <a href="" onClick={event => this.btn_delete_attachment(event,v.id)}>Remove</a>
                </div>
            </> )
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Cement Order Remove Attachment</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {imgs}
                                <div className="col-md-4 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:1,
                                                    })
                                                }}> Close
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-8 my-3">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_6 = () => {
        return (<>
            <div className="card-body">
                <div id="section_to_print_2" className="row" style={{fontSize:"16px"}}>
                    <div className="col-12 no-print">
                        <a href="" onClick={event => {
                            event.preventDefault()
                            window.print()
                        }}>Print</a>
                    </div>
                    <div className="col-3 pb-2 my-3">
                        <img className="img w-50" src={nt}></img>
                    </div>
                    <div className="col-9">
                    </div>
                    <div className="col-12 text-right">
                        <p><strong>ውል ቁጥር: {this.state.data.agreement_no}</strong></p>
                    </div>
                    <div className="col-12 text-center">
                        <strong>የሲሚንቶ ጭነት ማጓጓዝ ዉል</strong><br/>
                        <strong>አንቀጽ 1</strong><br/>
                        <strong>ተዋዋይ ወገኖች</strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        <p>1.1 <strong>ናሽናል ትራንስፖርት ኃ/የተ/የግል ማህበር </strong> አድራሻ አዲስ አበባ ስ/ቁ <strong>0930-01-44-04/ 0988-06-02-38 </strong> የግብር ከፋይ መለያ ቁጥር <strong>0043696904 </strong> ኢሜል <strong>info@nationaltransportplc.com</strong> ከዚህ  በኃላ ዉል ተቀባይ /አጓጓዥ/ እየተባለ በሚጠራዉ መካከል የተደረገ የደረቅ ጭነት የማጓጓዝ  ዉል ነዉ፡፡</p>
                        <p className="text-center">እና</p>
                        <p>1.2 <strong> {this.state.data.am_company_name} </strong> አድራሻ <strong>{this.state.data.am_address}</strong> ስ/ቁ <strong>{this.state.data.phone}</strong> የግብር ከፋይ መለያቁጥር <strong>{this.state.data.tin}</strong> ከዚህ በኃላ ዉል ሰጪ /አስጫኝ/ ተብሎ በሚጠራዉ፤ </p>
                    </div>
                    <div className="col-12 text-center">
                        <strong>አንቀጽ 2</strong><br/>
                        <strong>የዉሉ አላማ</strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        <p>2.1 ዉል ሰጪ /አስጫኝ/ ጭነቱን ለማጓጓዝ መኪኖች በመፈለጉ፣ እና ዉል ተቀባይ /አጓጓዥ/ እየተባለ የሚጠራው፣ ከዚህ በታች የተዘረዘሩትን፣ ጭነቶችን ወስዶ በወቅቱ እና ሳይበላሸ ከመነሻ ወደ መድረሻው ቦታ በተሰጠው ጊዚ ገደብ ውስጥ ለማጓጓዝ በግባው የስምምነት ዉል መሰረት ይህንን ውለታ ተግባራዊ ለማድረግ የ የጭነት ትራንስፖርት አገልግሎት ለማቅረብ በ ዉል ሰጪ /አስጫኝ/ እና ዉል ተቀባይ /አጓጓዥ/ የተደረገ ዉል ነዉ፡፡ </p>
                    </div>
                    <div className="col-12 text-center">
                        <strong>አንቀጽ 3</strong><br/>
                        <strong>የዉል ተቀባይ /አጓጓዥ/ ግዴታ</strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        <p>3.1 አጓጓዥ 400 ኩንታል የመጫን አቅም ያላቸው መኪኖች በማስማራት ከዚህ በታች ከተዘረዘሩት የጭነት አይነት፣ መነሻ እና መዳረሻዎች ለእያንዳንዱ በተቀመጠው ታሪፍ መሰረት____ኩንታል  ለመጫን ተስማምተዋል፡፡</p>
                        <table className="table table-sm table-bordered">
                            <tr>
                                <th>ተ.ቁ</th>
                                <th>የጭነት አይነት</th>
                                <th>መነሻ</th>
                                <th>መድረሻ</th>
                                <th>የኩንታል መጠን</th>
                                <th>የማጓጓዣ ዋጋ በኩንታል</th>
                                <th>ጠቅላላ ድምር</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>{this.state.data.cement_type}</td>
                                <td>{this.state.data.org}</td>
                                <td>{this.state.data.des}</td>
                                <td>{this.state.data.weight}</td>
                                <td>{this.state.data.transport_rate}</td>
                                <td>{this.state.data.total}</td>
                            </tr>
                        </table>
                    </div>
                    <div className="col-12 text-center">
                        <strong>አንቀጽ 4</strong><br/>
                        <strong>ዉል ተቀባይ /አጓጓዥ/ ግዴታ</strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        4.1	ክፍያዉ ሙሉ በሙሉ ከተከፈለ እና ዉል ሰጪ እቃዉን ለማስጫን ዠግጁ ሲሆን አጓጓዥ ጭነቱን ያጓጉዛል፡ <br/>
                        4.2	የመጫንና የማራገፍ ሁኔታዎች አቅም በፈቀደ አጓጓዥ ከአስጫኝ ጋር በመነጋገር በተራ ቁጥር 3.1 የተገለጸው የጭነት መጠን ያነሰ እና የበዛ መኪኖችን ሊያሠማራ ይችላል፡፡<br/>
                        4.3	 በማጓጓዝ ሂደቶች ላይ ለሚፈጠር ማንኛዉም ችግር በ ዉል ሰጪ /አስጫኝ/ እና ዉል ተቀባይ /አጓጓዥ/ በመነጋገር መፍትሄ ይሰጣል፡፡<br/>
                        4.4	ያለምንም ችግር በዉሉ ላይ የተጠቀሰዉ ጭነት እንዲያስረክብ ለታዘዘዉ ተረካቢ አካል እና ቦታ ጭነቱን የማድረስ ሃላፊነት አለበት፡፡<br/>
                        4.5	የመኪና ብልሽት ቢያጋጥም ተመሳሳይ ይዘት ያለዉ መኪና 3 ቀን ባልሞላ ወስጥ በመተካት ጭነቱን ፈጥኖ በማንሳት ጭነቱን መድረስ ያለበት ቦታ ያደርሳል፡<br/>
                        4.6	 ማንኛዉንም እቃ ሲረከብ በአግባቡ መታሸጋቸዉን አረጋግጦ መጫን አለበት፡፡<br/>
                    </div>
                    <div className="col-12 text-center">
                        <strong>አንቀጽ 5</strong><br/>
                        <strong>/የዉል ሰጪ /ግዴታ </strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        5.1  ጭነቱ ከመጓጓዙ በፊት በዉሉ መሰረት የማጓጓዣ ክፍያ ዉሉ የተፈረመ ቀን በዉል ተቀባይ ሂሳብ ቁጥር 100% ቅድመ ክፍያ ይከፍላል፡፡<br/>
                        5.2   ለጭነቱ ማራገፊያ የሚሆን በቂ ስፍራ ማዘጋጀት ግዴታ አለበት ፡፡<br/>
                        5.3  ጭነቱ መጋዘን ወይም ማራገፊያ ስፍራ እንደደረሰ በ 6 ሰዓት ዉስጥ እንዲያራግፉ የማድረግ ግዴታ አለበት ይህን ካላደረገ በትራንስፖርት ዲሚሪጅ አዋጅ መሰረት መኪኖቹ ለቆሙበት ክፍያ የመክፈል ግዴታ አለበት፡፡<br/>
                        5.4  መኪናው በማራገፍያ ቦታ ደርሶ ከ 2 ቀናት በላይ ሳያራግፍ ቢቆይ ከትራንስፖርት ዲመሬጅ አዋጅ 50% ቅጣት ተጨማሪ የሚከፍል ሲሆን ከ 2 ቀናት በላይ መኪናው ሳይራገፍለት ቢቆይ ከትራንስፖርት ዲመሬጅ አዋጅ ተጨማሪ 100% ቅጣት በእያንዳንዱ ቀን በመኪና ብዛት ይከፍላል፡፡<br/>
                        5.5  ጭነቱ በማራገፍያ ቦታ ደርሶ ነገር ግን በተቀመጠለት ጊዜ ገደብ ሳይራገፍ ቀርቶ በዝናብ ቢበላሽ አጓጓዥ ተጠያቂ አይሆንም፡፡<br/>
                    </div>
                    <div className="col-3 my-3">
                        <img className="img w-50" src={nt}></img>
                    </div>
                    <div className="col-12"></div>
                    <div className="col-12 text-justify">
                        5.6  ጭነቱ በማራገፍያ ቦታ ደርሶ ከ 3 ቀናት በላይ ሳያራገፍ ቢቆይ አጓጓዥ  የራሱ እርምጃ መውስድ ይችላል፡<br/>
                        5.7  የጫኝ እና አዉራጅ ክፍያዎችን የመሸፈን ግዴታ አለበት፡፡<br/>
                        5.8  የማራገፊያ ቦታዉ ለትራንስፖርት ምቹ እና ከ አደጋ የጸዳ ቦታ መሆን አለበት ይህ ሳይሆን ቀርቶ በማራገፍ ወቅት ለሚፈጠር ማንኛዉም ችግር ገዢ ሃላፊነቱን ይወስዳል፡፡<br/>
                        5.9  ጭነቱ ተራግፎ ሲጠናቀቅ ውል ሰጪው በድርጅቱ ማህተም ወይም የተረከበው አካል ፈርሞ መቀበሉን ያረጋግጣል፡፡<br/>
                        5.10  ከ 400 መቶ ኩንታል በታች ለሚጫን ጭነት ውል ሲጪ በመኪና የመጫን አቅም ማለትም በ 400 ኩንታል ታሳቢ የሚከፍል ሲሆን፤እና ከ 400 ኩንታል በላይ ለሚጫን ጭነት በኩንታል ታስቦ የሚከፈል ይሆናል፡፡        <br/><br/>
                    </div>
                    <div className="col-12 text-center">
                        <strong>አንቀጽ 6</strong><br/>
                        <strong>ዋጋ መጨመር እና መቀነስን በተመለከተ</strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        6.1	ጭነቱ ተጓጉዞ ሳያልቅ ዉል ተቀባይ የዋጋ ማስተካከያ ካደረገ እና የማጓጓዣ ሂሳብ የሚቀንስ ከሆነ ላልተጫነዉ ጭነት ብቻ የቅናሹ ተጠቃሚ የሆናል፡፡ ይህም ድጋሜ በሚያደርገዉ ጭነት ላይ ታሳቢ ሆኖ የሚቀነስ ይሆናል፡፡<br/>
                        6.2	 ጭነቱ ተጓጉዞ ሳያልቅ ድርጅቱ የዋጋ ጭማሪ ካደረገ እና የማጓጓዣ ሂሳብ የሚጨምር ከሆነ  ላልተጫነዉ ጭነት ብቻ ዉል ሰጪ  ጭማሪዉን የመክፈል ግዴታ ይኖርበታል፡፡<br/>
                        6.3	 ዉል ሰጪ ተጨማሪዉን ክፍያ ለመክፈል የማይስማማ ከሆነ  ዉል ተቀባይ  በቀሪዉ ሂሳብ መሰረት አዲሱን ዋጋ መሰረት አድርጎ ተመጣጣኝ የሆነ ጭነት   የማጓጓዝ አገልግሎት ይስጣል፡፡<br/>
                    </div>
                    <div className="col-12 text-center">
                        <strong>አንቀጽ 7</strong><br/>
                        <strong>አለመግባባትን ስለመፍታት</strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        7.1	ተዋዋይ ወገኖች ከዚህ ውል ጋር ተያይዞ የሚነሳውን አለመግባባት በመግባባት ይፈታሉ፡፡<br/>
                        7.2	ጉዳያቸዉን በመግባባት መፍታት ካልቻሉ ስልጣን ወዳለዉ ፍርድ ቤት ጉዳያቸዉን መዉሰድ ይችላሉ፡፡<br/>
                        7.3	ከአቅም በላይ በሆኑ ምክንያቶች (Force majeure) ለሚፈጠረው ችግር ሁለቱም ወገኖች ተጠያቄ አይሆኑም፡፡<br/>
                    </div>
                    <div className="col-12 text-center">
                        <strong>አንቀጽ 8</strong><br/>
                        <strong>ዉሉን ስለማፍረስ </strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        8.1	ከላይ የተቀመጡት ግዴታዎች ያልተፈጸሙ እንደሆነ በጉዳዩ ቅር የተሰኘዉ አካል የ03 ቀን ማስጠንቀቂያ በመስጠት ዉሉን ማፍረስ ይችላል፡፡<br/>
                    </div>

                    <div className="col-12 text-center">
                        <strong>አንቀጽ 9</strong><br/>
                        <strong>ከአቅም በላይ ሁኔታ</strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        9.1	በኢትዬጵያ ፍትኃብሄር ህግ ቁጥር አንቀጽ 1792 እና 1793 በተገለጸው መሰረት ከቁጥጥር ውጭ በሆነ ሁኔታ ለሚደርስ ችግር ተዋዋይ ወገኖች አንዱ በሌላው ላይ የሚያቀርበው የይገባኛል ጥያቄ አይኖርም፡፡ <br/>
                        9.2	ይህ ውል ከዛሬ <strong>{this.state.data.dt}</strong> ጀምሮ የፀና ይሆናል የውሉ ቅጂም በሁለቱ ወገኖች እጅ ይገኛል፡፡<br/><br/><br/>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col-6">
                                        <strong>ስለ ሻጭ</strong><br/><br/>
                                    </div>
                                    <div className="col-6">
                                        <strong>ስለ ገዢ</strong><br/><br/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"></div>

                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col-6">
                                        ስም: _____________________<br/><br/>
                                    </div>
                                    <div className="col-6">
                                        ስም: _____________________<br/><br/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"></div>

                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col-6">
                                        ፊርማ :_____________________<br/><br/>
                                    </div>
                                    <div className="col-6">
                                        ፊርማ :_____________________<br/><br/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"></div>

                            <div className="col-2"></div>
                            <div className="col-8 text-center">
                                <strong>ምስክሮች</strong><br/>
                            </div>
                            <div className="col-2"></div>

                            <div className="col-2"></div>
                            <div className="col-8 text-center">
                                <div className="row">
                                    <div className="col-6">
                                        1. ____________________________<br/><br/>
                                    </div>
                                    <div className="col-6">
                                        _______________________________<br/><br/>
                                    </div>

                                    <div className="col-6">
                                        2. ____________________________<br/><br/>
                                    </div>
                                    <div className="col-6">
                                        _______________________________<br/><br/>
                                    </div>

                                    <div className="col-6">
                                        3. ____________________________<br/><br/>
                                    </div>
                                    <div className="col-6">
                                        _______________________________<br/><br/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer clearfix no-print">
                <button type="button" className="btn btn-danger float-right" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        wiz: 1,
                    })
                }}><i
                    className="fas fa-arrow-left"></i>
                </button>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        else if (this.state.wiz === 4)
            dis = this.wiz_4()
        else if (this.state.wiz === 5)
            dis = this.wiz_5()
        else if (this.state.wiz === 6)
            dis = this.wiz_6()
        return (
            <>
                <Nav type="order_open"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle no-print"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Order Open</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Order_open;
