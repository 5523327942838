import {Component} from 'react';
import axios from "axios"
import myconfig from "../myconfig";
import Swal from "sweetalert2";
import logimg from "../img/logo.png";
import {Modal} from "react-bootstrap";


let source = axios.CancelToken.source();

class Nav extends Component {
    constructor() {
        super();

        this.state = {
            matrix: [],
            martixload: false,

            isexe:false,
            user_name:'',

            telegrammgt: 'nav-item',
            telegrammgt1: 'nav-link',
            tg_status_sum: 'nav-link',
            tg_status_det: 'nav-link',
            tg_status_single: 'nav-link',
            tg_stop_at_client: 'nav-link',
            tg_unloaded_client: 'nav-link',
            tg_coal_operation: 'nav-link',

            kpiplanmgt: 'nav-item',
            kpiplanmgt1: 'nav-link',
            kpi_umo: 'nav-link',
            kpi_description: 'nav-link',
            kpi_data: 'nav-link',

            purchasemgt: 'nav-item',
            purchasemgt1: 'nav-link',
            pr_prepare: 'nav-link',
            pr_check: 'nav-link',
            pr_app: 'nav-link',
            po_prepare: 'nav-link',
            po_chk: 'nav-link',
            po_app: 'nav-link',
            purchase: 'nav-link',
            purchase_status: 'nav-link',

            statusmgt: 'nav-item',
            statusmgt1: 'nav-link',
            status_purchase: 'nav-link',
            status_cement: 'nav-link',
            status_delivery: 'nav-link',
            status_cement_allocation: 'nav-link',
            status_purchase_detail: 'nav-link',
            status_cement_detail: 'nav-link',
            status_delivery_detail: 'nav-link',


            oprefundmgt: 'nav-item',
            oprefundmgt1: 'nav-link',
            op_refund_req: 'nav-link',
            op_refund_app: 'nav-link',
            op_trip_cancel_req: 'nav-link',
            op_trip_cancel_app: 'nav-link',
            op_trip_extend_req: 'nav-link',
            op_trip_extend_app: 'nav-link',
            op_reconcile: 'nav-link',

            dashmgt: 'nav-item',
            dashmgt1: 'nav-link',
            d_status: 'nav-link',
            d_operation: 'nav-link',
            d_operation_2: 'nav-link',
            d_marketing: 'nav-link',

            outsourcemgt: 'nav-item',
            outsourcemgt1: 'nav-link',
            os_company: 'nav-link',
            os_vehicle: 'nav-link',
            os_driver: 'nav-link',
            os_trailer: 'nav-link',
            assign_sub_contractor_req: 'nav-link',
            assign_sub_contractor_app: 'nav-link',
            sub_contractors: 'nav-link',
            supplier_company: 'nav-link',
            assign_supplier_company_req: 'nav-link',
            assign_supplier_company_app: 'nav-link',
            suppliers: 'nav-link',

            opexmgt: 'nav-item',
            opexmgt1: 'nav-link',
            opex_cost_req: 'nav-link',
            opex_cost_app: 'nav-link',
            opex_cost_payment: 'nav-link',
            opex_cost_payment_all: 'nav-link',
            opex_cost_payment_validator: 'nav-link',
            opex_cost_bank_tt: 'nav-link',
            opex_fuel_req: 'nav-link',
            opex_fuel_app: 'nav-link',
            opex_fuel_giver: 'nav-link',
            opex_cost_refund: 'nav-link',
            opex_fuel_refund: 'nav-link',
            opex_summuary: 'nav-link',
            opex_detail: 'nav-link',


            financemgt: 'nav-item',
            financemgt1: 'nav-link',
            finance_fo_by_date: 'nav-link',
            finance_fo_by_plate_no: 'nav-link',
            finance_canceled_fo: 'nav-link',
            finance_doc_rec: 'nav-link',
            finance_not_doc_rec: 'nav-link',

            ordermgt: 'nav-item',
            ordermgt1: 'nav-link',
            order_open: 'nav-link',
            cement_open: 'nav-link',
            cement_app: 'nav-link',
            cement_so: 'nav-link',
            cement_current_status: 'nav-link',
            cement_update: 'nav-link',
            cement_update_app: 'nav-link',
            cement_status: 'nav-link',
            order_accept: 'nav-link',
            order_accept_fin: 'nav-link',
            order_current_status: 'nav-link',
            order_status: 'nav-link',
            order_detail: 'nav-link',
            order_update: 'nav-link',
            order_op_update_app: 'nav-link',
            order_fin_update_app: 'nav-link',
            order_loading_adv_req: 'nav-link',
            order_loading_adv_app: 'nav-link',
            order_loading_adv: 'nav-link',
            cement_delivery: 'nav-link',
            cement_delivery_report: 'nav-link',
            cement_delivery_edit: 'nav-link',
            cement_delivery_cancel_req: 'nav-link',
            cement_delivery_cancel_app: 'nav-link',

            usermgt: 'nav-item',
            usermgt1: 'nav-link',
            user: 'nav-link',
            objuser: 'nav-link',
            useraccess1: 'nav-link',
            user_template: 'nav-link',

            managmentmgt: 'nav-item',
            managmentmgt1: 'nav-link',
            client_mgt: 'nav-link',
            client_type: 'nav-link',
            coc_mgt: 'nav-link',
            cargo_type: 'nav-link',
            admin_script: 'nav-link',

            operationmgt: 'nav-item',
            operationmgt1: 'nav-link',
            order: 'nav-link',
            assign_own: 'nav-link',
            update_allocate: 'nav-link',
            cancel_allocate: 'nav-link',
            update_own: 'nav-link',
            delete_own: 'nav-link',
            payment_req: 'nav-link',
            payment_app: 'nav-link',
            payment_pay: 'nav-link',
            document_mgt: 'nav-link',
            void_fo: 'nav-link',
            veh_ava: 'nav-link',
            main_temp: 'nav-link',

            controalmgt: 'nav-item',
            controalmgt1: 'nav-link',
            follow_up: 'nav-link',
            follow_up_2: 'nav-link',
            follow_up_loading_unloading: 'nav-link',
            follow_up_transit: 'nav-link',
            follow_up_wfo: 'nav-link',
            vehicle_status: 'nav-link',
            follow_up_hisotry: 'nav-link',
            follow_up_movement: 'nav-link',
            follow_up_eta: 'nav-link',
            follow_up_stoppage: 'nav-link',
            follow_up_distance_calc: 'nav-link',
            follow_up_wizard: 'nav-link',
            follow_up_customer: 'nav-link',
            follow_up_report: 'nav-link',
            follow_up_manual: 'nav-link',

            assetmgt: 'nav-item',
            assetmgt1: 'nav-link',
            vehicle: 'nav-link',
            driver: 'nav-link',
            trailer: 'nav-link',
            location: 'nav-link',
            road_route: 'nav-link',
            road_route_open: 'nav-link',
            other_cost: 'nav-link',
            status_type: 'nav-link',
            fuel_rate: 'nav-link',

            dailymgt: 'nav-item',
            dailymgt1: 'nav-link',
            d_served: 'nav-link',
            d_not_served: 'nav-link',
            d_sale: 'nav-link',
            d_sale_detail: 'nav-link',
            d_sale_per_user: 'nav-link',

            reportmgt: 'nav-item',
            reportmgt1: 'nav-link',
            r_trip_master: 'nav-link',
            r_load_factor: 'nav-link',
            r_maintance: 'nav-link',
            r_ava: 'nav-link',
            r_tat: 'nav-link',
            r_detail_tat: 'nav-link',
            r_fright_order: 'nav-link',
            r_daily_stoppage: 'nav-link',
            r_movement: 'nav-link',
            r_tac: 'nav-link',
            r_tac_current: 'nav-link',
            r_time_to_load: 'nav-link',
            r_time_to_load_open: 'nav-link',

            garagemgt: 'nav-item',
            garagemgt1: 'nav-link',
            g_in_req: 'nav-link',
            g_in_app: 'nav-link',
            g_out_req: 'nav-link',
            g_out_app: 'nav-link',
            g_status: 'nav-link',


            insurancemgt: 'nav-item',
            insurancemgt1: 'nav-link',
            vehicle_insurance: 'nav-link',
            insurance_type: 'nav-link',
            driver_insurance: 'nav-link',
            trailer_insurance: 'nav-link',

            changepwd: 'nav-link',

            report_generator: 'nav-link',

        }
    }

    fetcheduseraccess = () => {
        let baseurl = myconfig.getuasession;
        let sid = window.localStorage.getItem("logmgtsys_sid")
        axios.post(baseurl, {
            sid: sid,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_Siunit.jsname", null);
                var win = window.open(myconfig.redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    matrix: res.data.datas,
                    user_name: res.data.name,
                    martixload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentDidMount() {
       /* if (!("Notification" in window)) {
            console.log("Browser does not support desktop notification");
        } else {
            Notification.requestPermission();
            let notfication = new Notification('Hello World').onclick = (event) => {
                event.preventDefault(); // prevent the browser from focusing the Notification's tab
                window.open("http://www.mozilla.org", "_blank");
            };
        }*/
        this.fetcheduseraccess();
        if (this.props.type == "d_status") {
            this.setState(
                {
                    dashmgt: 'nav-item menu-open active',
                    dashmgt1: 'nav-link active',
                    d_status: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "d_operation") {
            this.setState(
                {
                    dashmgt: 'nav-item menu-open active',
                    dashmgt1: 'nav-link active',
                    d_operation: 'nav-link active',
                }
            );
        }  else if (this.props.type == "d_operation_2") {
            this.setState(
                {
                    dashmgt: 'nav-item menu-open active',
                    dashmgt1: 'nav-link active',
                    d_operation_2: 'nav-link active',
                }
            );
        }else if (this.props.type == "d_marketing") {
            this.setState(
                {
                    dashmgt: 'nav-item menu-open active',
                    dashmgt1: 'nav-link active',
                    d_marketing: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "tg_status_sum") {
            this.setState(
                {
                    telegrammgt: 'nav-item menu-open active',
                    telegrammgt1: 'nav-link active',
                    tg_status_sum: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "tg_status_det") {
            this.setState(
                {
                    telegrammgt: 'nav-item menu-open active',
                    telegrammgt1: 'nav-link active',
                    tg_status_det: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "tg_status_single") {
            this.setState(
                {
                    telegrammgt: 'nav-item menu-open active',
                    telegrammgt1: 'nav-link active',
                    tg_status_single: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "tg_stop_at_client") {
            this.setState(
                {
                    telegrammgt: 'nav-item menu-open active',
                    telegrammgt1: 'nav-link active',
                    tg_stop_at_client: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "tg_unloaded_client") {
            this.setState(
                {
                    telegrammgt: 'nav-item menu-open active',
                    telegrammgt1: 'nav-link active',
                    tg_unloaded_client: 'nav-link active',
                }
            );
        }else if (this.props.type == "tg_coal_operation") {
            this.setState(
                {
                    telegrammgt: 'nav-item menu-open active',
                    telegrammgt1: 'nav-link active',
                    tg_coal_operation: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "report_generator") {
            this.setState(
                {
                    report_generator: 'nav-link active',
                }
            );
        }else if (this.props.type == "user") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    user: 'nav-link active',
                }
            );
        } else if (this.props.type == "objuser") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    objuser: 'nav-link active',
                }
            );
        } else if (this.props.type == "useraccess1") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    useraccess1: 'nav-link active',
                }
            );
        }else if (this.props.type == "user_template") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    user_template: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "client_mgt") {
            this.setState(
                {
                    managmentmgt: 'nav-item menu-open active',
                    managmentmgt1: 'nav-link active',
                    client_mgt: 'nav-link active',
                }
            );
        }else if (this.props.type == "client_type") {
            this.setState(
                {
                    managmentmgt: 'nav-item menu-open active',
                    managmentmgt1: 'nav-link active',
                    client_type: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "kpi_umo") {
            this.setState(
                {
                    kpiplanmgt: 'nav-item menu-open active',
                    kpiplanmgt1: 'nav-link active',
                    kpi_umo: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "kpi_description") {
            this.setState(
                {
                    kpiplanmgt: 'nav-item menu-open active',
                    kpiplanmgt1: 'nav-link active',
                    kpi_description: 'nav-link active',
                }
            );
        }else if (this.props.type == "kpi_data") {
            this.setState(
                {
                    kpiplanmgt: 'nav-item menu-open active',
                    kpiplanmgt1: 'nav-link active',
                    kpi_data: 'nav-link active',
                }
            );
        }else if (this.props.type == "coc_mgt") {
            this.setState(
                {
                    managmentmgt: 'nav-item menu-open active',
                    managmentmgt1: 'nav-link active',
                    coc_mgt: 'nav-link active',
                }
            );
        }else if (this.props.type == "cargo_type") {
            this.setState(
                {
                    managmentmgt: 'nav-item menu-open active',
                    managmentmgt1: 'nav-link active',
                    cargo_type: 'nav-link active',
                }
            );
        }else if (this.props.type == "admin_script") {
            this.setState(
                {
                    managmentmgt: 'nav-item menu-open active',
                    managmentmgt1: 'nav-link active',
                    admin_script: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "order") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    order: 'nav-link active',
                }
            );
        }else if (this.props.type == "assign_own") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    assign_own: 'nav-link active',
                }
            );
        }else if (this.props.type == "update_allocate") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    update_allocate: 'nav-link active',
                }
            );
        }else if (this.props.type == "cancel_allocate") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    cancel_allocate: 'nav-link active',
                }
            );
        }else if (this.props.type == "update_own") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    update_own: 'nav-link active',
                }
            );
        }else if (this.props.type == "delete_own") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    delete_own: 'nav-link active',
                }
            );
        }else if (this.props.type == "payment_req") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    payment_req: 'nav-link active',
                }
            );
        }else if (this.props.type == "payment_app") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    payment_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "payment_pay") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    payment_pay: 'nav-link active',
                }
            );
        }else if (this.props.type == "document_mgt") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    document_mgt: 'nav-link active',
                }
            );
        }else if (this.props.type == "void_fo") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    void_fo: 'nav-link active',
                }
            );
        }else if (this.props.type == "veh_ava") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    veh_ava: 'nav-link active',
                }
            );
        }else if (this.props.type == "main_temp") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    main_temp: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "vehicle") {
            this.setState(
                {
                    assetmgt: 'nav-item menu-open active',
                    assetmgt1: 'nav-link active',
                    vehicle: 'nav-link active',
                }
            );
        } else if (this.props.type == "driver") {
            this.setState(
                {
                    assetmgt: 'nav-item menu-open active',
                    assetmgt1: 'nav-link active',
                    driver: 'nav-link active',
                }
            );
        }else if (this.props.type == "trailer") {
            this.setState(
                {
                    assetmgt: 'nav-item menu-open active',
                    assetmgt1: 'nav-link active',
                    trailer: 'nav-link active',
                }
            );
        }else if (this.props.type == "location") {
            this.setState(
                {
                    assetmgt: 'nav-item menu-open active',
                    assetmgt1: 'nav-link active',
                    location: 'nav-link active',
                }
            );
        }else if (this.props.type == "road_route") {
            this.setState(
                {
                    assetmgt: 'nav-item menu-open active',
                    assetmgt1: 'nav-link active',
                    road_route: 'nav-link active',
                }
            );
        }else if (this.props.type == "road_route_open") {
            this.setState(
                {
                    assetmgt: 'nav-item menu-open active',
                    assetmgt1: 'nav-link active',
                    road_route_open: 'nav-link active',
                }
            );
        }else if (this.props.type == "other_cost") {
            this.setState(
                {
                    assetmgt: 'nav-item menu-open active',
                    assetmgt1: 'nav-link active',
                    other_cost: 'nav-link active',
                }
            );
        }else if (this.props.type == "status_type") {
            this.setState(
                {
                    assetmgt: 'nav-item menu-open active',
                    assetmgt1: 'nav-link active',
                    status_type: 'nav-link active',
                }
            );
        }else if (this.props.type == "fuel_rate") {
            this.setState(
                {
                    assetmgt: 'nav-item menu-open active',
                    assetmgt1: 'nav-link active',
                    fuel_rate: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "os_company") {
            this.setState(
                {
                    outsourcemgt: 'nav-item menu-open active',
                    outsourcemgt1: 'nav-link active',
                    os_company: 'nav-link active',
                }
            );
        }else if (this.props.type == "os_vehicle") {
            this.setState(
                {
                    outsourcemgt: 'nav-item menu-open active',
                    outsourcemgt1: 'nav-link active',
                    os_vehicle: 'nav-link active',
                }
            );
        }else if (this.props.type == "os_driver") {
            this.setState(
                {
                    outsourcemgt: 'nav-item menu-open active',
                    outsourcemgt1: 'nav-link active',
                    os_driver: 'nav-link active',
                }
            );
        }else if (this.props.type == "os_trailer") {
            this.setState(
                {
                    outsourcemgt: 'nav-item menu-open active',
                    outsourcemgt1: 'nav-link active',
                    os_trailer: 'nav-link active',
                }
            );
        }else if (this.props.type == "assign_sub_contractor_req") {
            this.setState(
                {
                    outsourcemgt: 'nav-item menu-open active',
                    outsourcemgt1: 'nav-link active',
                    assign_sub_contractor_req: 'nav-link active',
                }
            );
        }else if (this.props.type == "assign_sub_contractor_app") {
            this.setState(
                {
                    outsourcemgt: 'nav-item menu-open active',
                    outsourcemgt1: 'nav-link active',
                    assign_sub_contractor_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "sub_contractors") {
            this.setState(
                {
                    outsourcemgt: 'nav-item menu-open active',
                    outsourcemgt1: 'nav-link active',
                    sub_contractors: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "supplier_company") {
            this.setState(
                {
                    outsourcemgt: 'nav-item menu-open active',
                    outsourcemgt1: 'nav-link active',
                    supplier_company: 'nav-link active',
                }
            );
        }else if (this.props.type == "assign_supplier_company_req") {
            this.setState(
                {
                    outsourcemgt: 'nav-item menu-open active',
                    outsourcemgt1: 'nav-link active',
                    assign_supplier_company_req: 'nav-link active',
                }
            );
        }else if (this.props.type == "assign_supplier_company_app") {
            this.setState(
                {
                    outsourcemgt: 'nav-item menu-open active',
                    outsourcemgt1: 'nav-link active',
                    assign_supplier_company_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "suppliers") {
            this.setState(
                {
                    outsourcemgt: 'nav-item menu-open active',
                    outsourcemgt1: 'nav-link active',
                    suppliers: 'nav-link active',
                }
            );
        }

        else if (this.props.type == "order_open") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    order_open: 'nav-link active',
                }
            );
        }  else if (this.props.type == "cement_open") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    cement_open: 'nav-link active',
                }
            );
        }else if (this.props.type == "cement_app") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    cement_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "cement_so") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    cement_so: 'nav-link active',
                }
            );
        }else if (this.props.type == "cement_current_status") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    cement_current_status: 'nav-link active',
                }
            );
        }else if (this.props.type == "cement_update") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    cement_update: 'nav-link active',
                }
            );
        }else if (this.props.type == "cement_update_app") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    cement_update_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "cement_status") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    cement_status: 'nav-link active',
                }
            );
        }else if (this.props.type == "order_accept") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    order_accept: 'nav-link active',
                }
            );
        }else if (this.props.type == "order_accept_fin") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    order_accept_fin: 'nav-link active',
                }
            );
        }else if (this.props.type == "order_current_status") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    order_current_status: 'nav-link active',
                }
            );
        }else if (this.props.type == "order_update") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    order_update: 'nav-link active',
                }
            );
        }else if (this.props.type == "order_op_update_app") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    order_op_update_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "order_fin_update_app") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    order_fin_update_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "order_loading_adv_req") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    order_loading_adv_req: 'nav-link active',
                }
            );
        }else if (this.props.type == "order_loading_adv_app") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    order_loading_adv_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "order_loading_adv") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    order_loading_adv: 'nav-link active',
                }
            );
        }else if (this.props.type == "order_status") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    order_status: 'nav-link active',
                }
            );
        }else if (this.props.type == "cement_delivery") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    cement_delivery: 'nav-link active',
                }
            );
        }else if (this.props.type == "cement_delivery_report") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    cement_delivery_report: 'nav-link active',
                }
            );
        }else if (this.props.type == "cement_delivery_edit") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    cement_delivery_edit: 'nav-link active',
                }
            );
        }else if (this.props.type == "cement_delivery_cancel_req") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    cement_delivery_cancel_req: 'nav-link active',
                }
            );
        }else if (this.props.type == "cement_delivery_cancel_app") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    cement_delivery_cancel_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "order_detail") {
            this.setState(
                {
                    ordermgt: 'nav-item menu-open active',
                    ordermgt1: 'nav-link active',
                    order_detail: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "vehicle_insurance") {
            this.setState(
                {
                    insurancemgt: 'nav-item menu-open active',
                    insurancemgt1: 'nav-link active',
                    vehicle_insurance: 'nav-link active',
                }
            );
        }else if (this.props.type == "insurance_type") {
            this.setState(
                {
                    insurancemgt: 'nav-item menu-open active',
                    insurancemgt1: 'nav-link active',
                    insurance_type: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "driver_insurance") {
            this.setState(
                {
                    insurancemgt: 'nav-item menu-open active',
                    insurancemgt1: 'nav-link active',
                    driver_insurance: 'nav-link active',
                }
            );
        } else if (this.props.type == "trailer_insurance") {
            this.setState(
                {
                    insurancemgt: 'nav-item menu-open active',
                    insurancemgt1: 'nav-link active',
                    trailer_insurance: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "follow_up") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    follow_up: 'nav-link active',
                }
            );
        }  else if (this.props.type == "follow_up_2") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    follow_up_2: 'nav-link active',
                }
            );
        }else if (this.props.type == "follow_up_loading_unloading") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    follow_up_loading_unloading: 'nav-link active',
                }
            );
        }else if (this.props.type == "follow_up_transit") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    follow_up_transit: 'nav-link active',
                }
            );
        }else if (this.props.type == "follow_up_wfo") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    follow_up_wfo: 'nav-link active',
                }
            );
        }else if (this.props.type == "vehicle_status") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    vehicle_status: 'nav-link active',
                }
            );
        }else if (this.props.type == "follow_up_hisotry") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    follow_up_hisotry: 'nav-link active',
                }
            );
        }else if (this.props.type == "follow_up_movement") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    follow_up_movement: 'nav-link active',
                }
            );
        }else if (this.props.type == "follow_up_eta") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    follow_up_eta: 'nav-link active',
                }
            );
        }else if (this.props.type == "follow_up_stoppage") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    follow_up_stoppage: 'nav-link active',
                }
            );
        }else if (this.props.type == "follow_up_distance_calc") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    follow_up_distance_calc: 'nav-link active',
                }
            );
        }else if (this.props.type == "follow_up_wizard") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    follow_up_wizard: 'nav-link active',
                }
            );
        }else if (this.props.type == "follow_up_customer") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    follow_up_customer: 'nav-link active',
                }
            );
        }else if (this.props.type == "follow_up_report") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    follow_up_report: 'nav-link active',
                }
            );
        }else if (this.props.type == "follow_up_manual") {
            this.setState(
                {
                    controalmgt: 'nav-item menu-open active',
                    controalmgt1: 'nav-link active',
                    follow_up_manual: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "g_in_req") {
            this.setState(
                {
                    garagemgt: 'nav-item menu-open active',
                    garagemgt1: 'nav-link active',
                    g_in_req: 'nav-link active',
                }
            );
        }  else if (this.props.type == "g_in_app") {
            this.setState(
                {
                    garagemgt: 'nav-item menu-open active',
                    garagemgt1: 'nav-link active',
                    g_in_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "g_out_req") {
            this.setState(
                {
                    garagemgt: 'nav-item menu-open active',
                    garagemgt1: 'nav-link active',
                    g_out_req: 'nav-link active',
                }
            );
        }else if (this.props.type == "g_out_app") {
            this.setState(
                {
                    garagemgt: 'nav-item menu-open active',
                    garagemgt1: 'nav-link active',
                    g_out_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "g_status") {
            this.setState(
                {
                    garagemgt: 'nav-item menu-open active',
                    garagemgt1: 'nav-link active',
                    g_status: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "finance_fo_by_date") {
            this.setState(
                {
                    financemgt: 'nav-item menu-open active',
                    financemgt1: 'nav-link active',
                    finance_fo_by_date: 'nav-link active',
                }
            );
        }else if (this.props.type == "finance_fo_by_plate_no") {
            this.setState(
                {
                    financemgt: 'nav-item menu-open active',
                    financemgt1: 'nav-link active',
                    finance_fo_by_plate_no: 'nav-link active',
                }
            );
        }else if (this.props.type == "finance_canceled_fo") {
            this.setState(
                {
                    financemgt: 'nav-item menu-open active',
                    financemgt1: 'nav-link active',
                    finance_canceled_fo: 'nav-link active',
                }
            );
        }else if (this.props.type == "finance_doc_rec") {
            this.setState(
                {
                    financemgt: 'nav-item menu-open active',
                    financemgt1: 'nav-link active',
                    finance_doc_rec: 'nav-link active',
                }
            );
        }else if (this.props.type == "finance_not_doc_rec") {
            this.setState(
                {
                    financemgt: 'nav-item menu-open active',
                    financemgt1: 'nav-link active',
                    finance_not_doc_rec: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "opex_cost_req") {
            this.setState(
                {
                    opexmgt: 'nav-item menu-open active',
                    opexmgt1: 'nav-link active',
                    opex_cost_req: 'nav-link active',
                }
            );
        } else if (this.props.type == "opex_cost_app") {
            this.setState(
                {
                    opexmgt: 'nav-item menu-open active',
                    opexmgt1: 'nav-link active',
                    opex_cost_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "opex_cost_payment") {
            this.setState(
                {
                    opexmgt: 'nav-item menu-open active',
                    opexmgt1: 'nav-link active',
                    opex_cost_payment: 'nav-link active',
                }
            );
        }else if (this.props.type == "opex_cost_payment_all") {
            this.setState(
                {
                    opexmgt: 'nav-item menu-open active',
                    opexmgt1: 'nav-link active',
                    opex_cost_payment_all: 'nav-link active',
                }
            );
        }else if (this.props.type == "opex_cost_payment_validator") {
            this.setState(
                {
                    opexmgt: 'nav-item menu-open active',
                    opexmgt1: 'nav-link active',
                    opex_cost_payment_validator: 'nav-link active',
                }
            );
        }else if (this.props.type == "opex_cost_bank_tt") {
            this.setState(
                {
                    opexmgt: 'nav-item menu-open active',
                    opexmgt1: 'nav-link active',
                    opex_cost_bank_tt: 'nav-link active',
                }
            );
        }else if (this.props.type == "opex_fuel_req") {
            this.setState(
                {
                    opexmgt: 'nav-item menu-open active',
                    opexmgt1: 'nav-link active',
                    opex_fuel_req: 'nav-link active',
                }
            );
        }else if (this.props.type == "opex_fuel_app") {
            this.setState(
                {
                    opexmgt: 'nav-item menu-open active',
                    opexmgt1: 'nav-link active',
                    opex_fuel_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "opex_fuel_giver") {
            this.setState(
                {
                    opexmgt: 'nav-item menu-open active',
                    opexmgt1: 'nav-link active',
                    opex_fuel_giver: 'nav-link active',
                }
            );
        }else if (this.props.type == "opex_cost_refund") {
            this.setState(
                {
                    opexmgt: 'nav-item menu-open active',
                    opexmgt1: 'nav-link active',
                    opex_cost_refund: 'nav-link active',
                }
            );
        }else if (this.props.type == "opex_fuel_refund") {
            this.setState(
                {
                    opexmgt: 'nav-item menu-open active',
                    opexmgt1: 'nav-link active',
                    opex_fuel_refund: 'nav-link active',
                }
            );
        }else if (this.props.type == "opex_summuary") {
            this.setState(
                {
                    opexmgt: 'nav-item menu-open active',
                    opexmgt1: 'nav-link active',
                    opex_summuary: 'nav-link active',
                }
            );
        }else if (this.props.type == "opex_detail") {
            this.setState(
                {
                    opexmgt: 'nav-item menu-open active',
                    opexmgt1: 'nav-link active',
                    opex_detail: 'nav-link active',
                }
            );
        }else if (this.props.type == "status_purchase") {
            this.setState(
                {
                    statusmgt: 'nav-item menu-open active',
                    statusmgt1: 'nav-link active',
                    status_purchase: 'nav-link active',
                }
            );
        }else if (this.props.type == "status_cement") {
            this.setState(
                {
                    statusmgt: 'nav-item menu-open active',
                    statusmgt1: 'nav-link active',
                    status_cement: 'nav-link active',
                }
            );
        }else if (this.props.type == "status_delivery") {
            this.setState(
                {
                    statusmgt: 'nav-item menu-open active',
                    statusmgt1: 'nav-link active',
                    status_delivery: 'nav-link active',
                }
            );
        }else if (this.props.type == "status_cement_allocation") {
            this.setState(
                {
                    statusmgt: 'nav-item menu-open active',
                    statusmgt1: 'nav-link active',
                    status_cement_allocation: 'nav-link active',
                }
            );
        }else if (this.props.type == "status_purchase_detail") {
            this.setState(
                {
                    statusmgt: 'nav-item menu-open active',
                    statusmgt1: 'nav-link active',
                    status_purchase_detail: 'nav-link active',
                }
            );
        }else if (this.props.type == "status_cement_detail") {
            this.setState(
                {
                    statusmgt: 'nav-item menu-open active',
                    statusmgt1: 'nav-link active',
                    status_cement_detail: 'nav-link active',
                }
            );
        }else if (this.props.type == "status_delivery_detail") {
            this.setState(
                {
                    statusmgt: 'nav-item menu-open active',
                    statusmgt1: 'nav-link active',
                    status_delivery_detail: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "op_refund_req") {
            this.setState(
                {
                    oprefundmgt: 'nav-item menu-open active',
                    oprefundmgt1: 'nav-link active',
                    op_refund_req: 'nav-link active',
                }
            );
        }else if (this.props.type == "op_refund_app") {
            this.setState(
                {
                    oprefundmgt: 'nav-item menu-open active',
                    oprefundmgt1: 'nav-link active',
                    op_refund_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "op_trip_cancel_req") {
            this.setState(
                {
                    oprefundmgt: 'nav-item menu-open active',
                    oprefundmgt1: 'nav-link active',
                    op_trip_cancel_req: 'nav-link active',
                }
            );
        }else if (this.props.type == "op_trip_cancel_app") {
            this.setState(
                {
                    oprefundmgt: 'nav-item menu-open active',
                    oprefundmgt1: 'nav-link active',
                    op_trip_cancel_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "op_trip_extend_req") {
            this.setState(
                {
                    oprefundmgt: 'nav-item menu-open active',
                    oprefundmgt1: 'nav-link active',
                    op_trip_extend_req: 'nav-link active',
                }
            );
        }else if (this.props.type == "op_trip_extend_app") {
            this.setState(
                {
                    oprefundmgt: 'nav-item menu-open active',
                    oprefundmgt1: 'nav-link active',
                    op_trip_extend_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "op_reconcile") {
            this.setState(
                {
                    oprefundmgt: 'nav-item menu-open active',
                    oprefundmgt1: 'nav-link active',
                    op_reconcile: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "pr_prepare") {
            this.setState(
                {
                    purchasemgt: 'nav-item menu-open active',
                    purchasemgt1: 'nav-link active',
                    pr_prepare: 'nav-link active',
                }
            );
        }else if (this.props.type == "pr_check") {
            this.setState(
                {
                    purchasemgt: 'nav-item menu-open active',
                    purchasemgt1: 'nav-link active',
                    pr_check: 'nav-link active',
                }
            );
        }else if (this.props.type == "pr_app") {
            this.setState(
                {
                    purchasemgt: 'nav-item menu-open active',
                    purchasemgt1: 'nav-link active',
                    pr_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "po_prepare") {
            this.setState(
                {
                    purchasemgt: 'nav-item menu-open active',
                    purchasemgt1: 'nav-link active',
                    po_prepare: 'nav-link active',
                }
            );
        }else if (this.props.type == "po_chk") {
            this.setState(
                {
                    purchasemgt: 'nav-item menu-open active',
                    purchasemgt1: 'nav-link active',
                    po_chk: 'nav-link active',
                }
            );
        }else if (this.props.type == "po_app") {
            this.setState(
                {
                    purchasemgt: 'nav-item menu-open active',
                    purchasemgt1: 'nav-link active',
                    po_app: 'nav-link active',
                }
            );
        }else if (this.props.type == "purchase") {
            this.setState(
                {
                    purchasemgt: 'nav-item menu-open active',
                    purchasemgt1: 'nav-link active',
                    purchase: 'nav-link active',
                }
            );
        }else if (this.props.type == "purchase_status") {
            this.setState(
                {
                    purchasemgt: 'nav-item menu-open active',
                    purchasemgt1: 'nav-link active',
                    purchase_status: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "order_execute") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    order_execute: 'nav-link active',
                }
            );
        }else if (this.props.type == "order_view_2") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    order_view_2: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "order_view") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    order_view: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "z_report") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    z_report: 'nav-link active',
                }
            );
        } else if (this.props.type == "order_v2") {
            this.setState(
                {
                    operationmgt: 'nav-item menu-open active',
                    operationmgt1: 'nav-link active',
                    order_v2: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "r_trip_master") {
            this.setState(
                {
                    reportmgt: 'nav-item menu-open active',
                    reportmgt1: 'nav-link active',
                    r_trip_master: 'nav-link active',
                }
            );
        }else if (this.props.type == "r_maintance") {
            this.setState(
                {
                    reportmgt: 'nav-item menu-open active',
                    reportmgt1: 'nav-link active',
                    r_maintance: 'nav-link active',
                }
            );
        }else if (this.props.type == "r_ava") {
            this.setState(
                {
                    reportmgt: 'nav-item menu-open active',
                    reportmgt1: 'nav-link active',
                    r_ava: 'nav-link active',
                }
            );
        }else if (this.props.type == "r_tat") {
            this.setState(
                {
                    reportmgt: 'nav-item menu-open active',
                    reportmgt1: 'nav-link active',
                    r_tat: 'nav-link active',
                }
            );
        }else if (this.props.type == "r_detail_tat") {
            this.setState(
                {
                    reportmgt: 'nav-item menu-open active',
                    reportmgt1: 'nav-link active',
                    r_detail_tat: 'nav-link active',
                }
            );
        }else if (this.props.type == "r_fright_order") {
            this.setState(
                {
                    reportmgt: 'nav-item menu-open active',
                    reportmgt1: 'nav-link active',
                    r_fright_order: 'nav-link active',
                }
            );
        }else if (this.props.type == "r_daily_stoppage") {
            this.setState(
                {
                    reportmgt: 'nav-item menu-open active',
                    reportmgt1: 'nav-link active',
                    r_daily_stoppage: 'nav-link active',
                }
            );
        }else if (this.props.type == "r_movement") {
            this.setState(
                {
                    reportmgt: 'nav-item menu-open active',
                    reportmgt1: 'nav-link active',
                    r_movement: 'nav-link active',
                }
            );
        }else if (this.props.type == "r_tac") {
            this.setState(
                {
                    reportmgt: 'nav-item menu-open active',
                    reportmgt1: 'nav-link active',
                    r_tac: 'nav-link active',
                }
            );
        }else if (this.props.type == "r_tac_current") {
            this.setState(
                {
                    reportmgt: 'nav-item menu-open active',
                    reportmgt1: 'nav-link active',
                    r_tac_current: 'nav-link active',
                }
            );
        }else if (this.props.type == "r_time_to_load") {
            this.setState(
                {
                    reportmgt: 'nav-item menu-open active',
                    reportmgt1: 'nav-link active',
                    r_time_to_load: 'nav-link active',
                }
            );
        }else if (this.props.type == "r_time_to_load_open") {
            this.setState(
                {
                    reportmgt: 'nav-item menu-open active',
                    reportmgt1: 'nav-link active',
                    r_time_to_load_open: 'nav-link active',
                }
            );
        }

        else if (this.props.type == "d_served") {
            this.setState(
                {

                    dailymgt: 'nav-item menu-open active',
                    dailymgt1: 'nav-link active',
                    d_served: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "d_not_served") {
            this.setState(
                {

                    dailymgt: 'nav-item menu-open active',
                    dailymgt1: 'nav-link active',
                    d_not_served: 'nav-link active',
                }
            );
        } else if (this.props.type == "d_sale") {
            this.setState(
                {

                    dailymgt: 'nav-item menu-open active',
                    dailymgt1: 'nav-link active',
                    d_sale: 'nav-link active',
                }
            );
        }else if (this.props.type == "d_sale_detail") {
            this.setState(
                {

                    dailymgt: 'nav-item menu-open active',
                    dailymgt1: 'nav-link active',
                    d_sale_detail: 'nav-link active',
                }
            );
        }else if (this.props.type == "d_sale_per_user") {
                this.setState(
                    {

                        dailymgt: 'nav-item menu-open active',
                        dailymgt1: 'nav-link active',
                        d_sale_per_user: 'nav-link active',
                    }
                );
            }
    }
    logout = (event) => {
        event.preventDefault();
        let baseurl = myconfig.logout
        axios.post(baseurl, {
            'sid': window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
        }).catch((e) => {
            console.log(e.message);
        });
        window.localStorage.setItem("logmgtsys_sid", null);
        window.localStorage.setItem("logmgtsys_username", null);
        window.localStorage.setItem("logmgtsys_name", null);
        var win = window.open(myconfig.redirecttologin, "_self");
    }
    displaymenus = () => {
        if (this.state.martixload) {


            let tg_status_sum = "";
            let tg_status_det = "";
            let tg_status_single = "";
            let tg_stop_at_client = "";
            let tg_unloaded_client = "";
            let tg_coal_operation = "";
            let telegrammenu = "";
            let telegrammgt = this.state.matrix.filter(value => {
                if (value.object_name == "tg_status_sum" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    tg_status_sum =
                        <a href={myconfig.urltg_status_sum} className={this.state.tg_status_sum}><i className="far fa-circle nav-icon"/>
                            <p>Status Summery</p></a>
                    return value;
                }
                if (value.object_name == "tg_status_det" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    tg_status_det =
                        <a href={myconfig.urltg_status_det} className={this.state.tg_status_det}><i className="far fa-circle nav-icon"/>
                            <p>Status Detail</p></a>
                    return value;
                }
                if (value.object_name == "tg_status_single" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    tg_status_single =
                        <a href={myconfig.urltg_status_single} className={this.state.tg_status_single}><i className="far fa-circle nav-icon"/>
                            <p>Status Single</p></a>
                    return value;
                }
                if (value.object_name == "tg_stop_at_client" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    tg_stop_at_client =
                        <a href={myconfig.urltg_stop_at_client} className={this.state.tg_stop_at_client}><i className="far fa-circle nav-icon"/>
                            <p>Stop at Client</p></a>
                    return value;
                } if (value.object_name == "tg_unloaded_client" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    tg_unloaded_client =
                        <a href={myconfig.urltg_unloaded_client} className={this.state.tg_unloaded_client}><i className="far fa-circle nav-icon"/>
                            <p>Unloaded Vehicle</p></a>
                    return value;
                }if (value.object_name == "tg_coal_operation" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    tg_coal_operation =
                        <a href={myconfig.urltg_coal_operation} className={this.state.tg_coal_operation}><i className="far fa-circle nav-icon"/>
                            <p>Coal Operation</p></a>
                    return value;
                }
            })
            if (telegrammgt.length > 0) {
                telegrammenu = <li name="telegrammgt" onClick={(event => {
                    this.menuclick(event, this.state.telegrammgt)
                })} className={this.state.telegrammgt}>
                    <a name="telegrammgt" onClick={(event => {
                        this.menuclick(event, this.state.telegrammgt)
                    })} href="#" className={this.state.telegrammgt1}>
                        <i className="nav-icon fas fa-mail-bulk"/>
                        <p>
                            Telegram
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {tg_status_sum}
                            {tg_status_det}
                            {tg_status_single}
                            {tg_stop_at_client}
                            {tg_unloaded_client}
                            {tg_coal_operation}
                        </li>
                    </ul>
                </li>
            }


            let op_refund_req = "";
            let op_refund_app = "";
            let op_trip_cancel_req = "";
            let op_trip_cancel_app = "";
            let op_trip_extend_req = "";
            let op_trip_extend_app = "";
            let op_reconcile = "";
            let oprefundmenu = "";
            let oprefundmgt = this.state.matrix.filter(value => {
                if (value.object_name == "op_refund_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    op_refund_req =
                        <a href={myconfig.urlop_refund_req} className={this.state.op_refund_req}><i className="far fa-circle nav-icon"/>
                            <p>Refund REQ</p></a>
                    return value;
                } if (value.object_name == "op_refund_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    op_refund_app =
                        <a href={myconfig.urlop_refund_app} className={this.state.op_refund_app}><i className="far fa-circle nav-icon"/>
                            <p>Refund APP</p></a>
                    return value;
                }if (value.object_name == "op_trip_cancel_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    op_trip_cancel_req =
                        <a href={myconfig.urlop_trip_cancel_req} className={this.state.op_trip_cancel_req}><i className="far fa-circle nav-icon"/>
                            <p>FO Cancel REQ</p></a>
                    return value;
                }if (value.object_name == "op_trip_cancel_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    op_trip_cancel_app =
                        <a href={myconfig.urlop_trip_cancel_app} className={this.state.op_trip_cancel_app}><i className="far fa-circle nav-icon"/>
                            <p>FO Cancel APP</p></a>
                    return value;
                }if (value.object_name == "op_trip_extend_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    op_trip_extend_req =
                        <a href={myconfig.urlop_trip_extend_req} className={this.state.op_trip_extend_req}><i className="far fa-circle nav-icon"/>
                            <p>Trip Extender REQ</p></a>
                    return value;
                }if (value.object_name == "op_trip_extend_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    op_trip_extend_app =
                        <a href={myconfig.urlop_trip_extend_app} className={this.state.op_trip_extend_app}><i className="far fa-circle nav-icon"/>
                            <p>Trip Extender APP</p></a>
                    return value;
                }if (value.object_name == "op_reconcile" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    op_reconcile =
                        <a href={myconfig.urlop_reconcile} className={this.state.op_reconcile}><i className="far fa-circle nav-icon"/>
                            <p>Payment Reconcile</p></a>
                    return value;
                }
            })
            if (oprefundmgt.length > 0) {
                oprefundmenu = <li name="oprefundmgt" onClick={(event => {
                    this.menuclick(event, this.state.oprefundmgt)
                })} className={this.state.oprefundmgt}>
                    <a name="oprefundmgt" onClick={(event => {
                        this.menuclick(event, this.state.oprefundmgt)
                    })} href="#" className={this.state.oprefundmgt1}>
                        <i className="nav-icon fas fa-arrow-up"/>
                        <p>
                            Operation Refund
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {op_refund_req}
                            {op_refund_app}
                            {op_trip_cancel_req}
                            {op_trip_cancel_app}
                            {op_trip_extend_req}
                            {op_trip_extend_app}
                            {op_reconcile}
                        </li>
                    </ul>
                </li>
            }

            let d_status = "";
            let d_operation = "";
            let d_operation_2 = "";
            let d_marketing = "";
            let dashmenu = "";
            let dashmgt = this.state.matrix.filter(value => {
                if (value.object_name == "d_status" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    d_status =
                        <a href={myconfig.redirecttopublic_dashboard} target="_blank" className={this.state.d_status}><i className="far fa-circle nav-icon"/>
                            <p>Daily</p></a>
                    return value;
                }
                if (value.object_name == "d_operation" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    d_operation =
                        <a href={myconfig.redirecttod_operation} target="_blank" className={this.state.d_operation}><i className="far fa-circle nav-icon"/>
                            <p>Operation-1</p></a>
                    return value;
                }
                if (value.object_name == "d_operation_2" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    d_operation_2 =
                        <a href={myconfig.redirecttod_operation_2} target="_blank" className={this.state.d_operation_2}><i className="far fa-circle nav-icon"/>
                            <p>Operation-2</p></a>
                    return value;
                }
                if (value.object_name == "d_marketing" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    d_marketing =
                        <a href={myconfig.redirecttod_marketing} target="_blank" className={this.state.d_marketing}><i className="far fa-circle nav-icon"/>
                            <p>Marketing</p></a>
                    return value;
                }
            })
            if (dashmgt.length > 0) {
                dashmenu = <li name="dashmgt" onClick={(event => {
                    this.menuclick(event, this.state.dashmgt)
                })} className={this.state.dashmgt}>
                    <a name="dashmgt" onClick={(event => {
                        this.menuclick(event, this.state.dashmgt)
                    })} href="#" className={this.state.dashmgt1}>
                        <i className="nav-icon fas fa-tachometer-alt"/>
                        <p>
                            Dashboard
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {d_operation}
                            {d_operation_2}
                            {d_marketing}
                        </li>
                    </ul>
                </li>
            }

            let opex_cost_req = "";
            let opex_cost_app = "";
            let opex_cost_payment = "";
            let opex_cost_payment_all = "";
            let opex_cost_payment_validator = "";
            let opex_cost_bank_tt = "";
            let opex_fuel_req = "";
            let opex_fuel_app = "";
            let opex_fuel_giver = "";
            let opex_cost_refund = "";
            let opex_fuel_refund = "";
            let opex_summuary = "";
            let opex_detail = "";
            let opexmenu = "";
            let opexmgt = this.state.matrix.filter(value => {
                /*  if (value.object_name == "opex_cost_req" &&
                      (value.create || value.update || value.read || value.delete)
                  ) {
                      opex_cost_req =
                          <a href={myconfig.urlopex_cost_req} className={this.state.opex_cost_req}><i className="far fa-circle nav-icon"/>
                              <p>Truck Cost REQ</p></a>
                      return value;
                  }if (value.object_name == "opex_cost_app" &&
                      (value.create || value.update || value.read || value.delete)
                  ) {
                      opex_cost_app =
                          <a href={myconfig.urlopex_cost_app} className={this.state.opex_cost_app}><i className="far fa-circle nav-icon"/>
                              <p>Truck Cost APP</p></a>
                      return value;
                  }*/
                if (value.object_name == "opex_cost_payment" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    opex_cost_payment =
                        <a href={myconfig.urlopex_cost_payment} className={this.state.opex_cost_payment}><i className="far fa-circle nav-icon"/>
                            <p>Truck Cost Payment</p></a>
                    return value;
                }if (value.object_name == "opex_cost_payment_all" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    opex_cost_payment_all =
                        <a href={myconfig.urlopex_cost_payment_all} className={this.state.opex_cost_payment_all}><i className="far fa-circle nav-icon"/>
                            <p>Truck Cost Payment All</p></a>
                    return value;
                }if (value.object_name == "opex_cost_payment_validator" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    opex_cost_payment_validator =
                        <a href={myconfig.urlopex_cost_payment_validator} className={this.state.opex_cost_payment_validator}><i className="far fa-circle nav-icon"/>
                            <p>Payment Validator</p></a>
                    return value;
                }if (value.object_name == "opex_cost_bank_tt" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    opex_cost_bank_tt =
                        <a href={myconfig.urlopex_cost_bank_tt} className={this.state.opex_cost_bank_tt}><i className="far fa-circle nav-icon"/>
                            <p>Bank TT</p></a>
                    return value;
                }
                /*if (value.object_name == "opex_fuel_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    opex_fuel_req =
                        <a href={myconfig.urlopex_fuel_req} className={this.state.opex_fuel_req}><i className="far fa-circle nav-icon"/>
                            <p>Truck Fuel REQ</p></a>
                    return value;
                }if (value.object_name == "opex_fuel_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    opex_fuel_app =
                        <a href={myconfig.urlopex_fuel_app} className={this.state.opex_fuel_app}><i className="far fa-circle nav-icon"/>
                            <p>Truck Fuel APP</p></a>
                    return value;
                }*/
                if (value.object_name == "opex_fuel_giver" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    opex_fuel_giver =
                        <a href={myconfig.urlopex_fuel_giver} className={this.state.opex_fuel_giver}><i className="far fa-circle nav-icon"/>
                            <p>Truck Fuel Issue</p></a>
                    return value;
                }
                if (value.object_name == "opex_cost_refund" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    opex_cost_refund =
                        <a href={myconfig.urlopex_cost_refund} className={this.state.opex_cost_refund}><i className="far fa-circle nav-icon"/>
                            <p>Truck Cost Refund</p></a>
                    return value;
                }
                if (value.object_name == "opex_fuel_refund" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    opex_fuel_refund =
                        <a href={myconfig.urlopex_fuel_refund} className={this.state.opex_fuel_refund}><i className="far fa-circle nav-icon"/>
                            <p>Truck Fuel Refund</p></a>
                    return value;
                }if (value.object_name == "opex_summuary" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    opex_summuary =
                        <a href={myconfig.urlopex_summuary} className={this.state.opex_summuary}><i className="far fa-circle nav-icon"/>
                            <p>Truck Summary</p></a>
                    return value;
                }if (value.object_name == "opex_detail" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    opex_detail =
                        <a href={myconfig.urlopex_detail} className={this.state.opex_detail}><i className="far fa-circle nav-icon"/>
                            <p>FO Detail</p></a>
                    return value;
                }
            })
            if (opexmgt.length > 0) {
                opexmenu = <li name="opexmgt" onClick={(event => {
                    this.menuclick(event, this.state.opexmgt)
                })} className={this.state.opexmgt}>
                    <a name="opexmgt" onClick={(event => {
                        this.menuclick(event, this.state.opexmgt)
                    })} href="#" className={this.state.opexmgt1}>
                        <i className="nav-icon fas fa-th"/>
                        <p>
                            OPEX
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {opex_cost_req}
                            {opex_cost_app}
                            {opex_cost_payment}
                            {opex_cost_payment_all}
                            {opex_cost_payment_validator}
                            {opex_cost_bank_tt}
                            {opex_fuel_req}
                            {opex_fuel_app}
                            {opex_fuel_giver}
                            {opex_cost_refund}
                            {opex_fuel_refund}
                            {opex_summuary}
                            {opex_detail}
                        </li>
                    </ul>
                </li>
            }

            let pr_prepare = "";
            let pr_check = "";
            let pr_app = "";
            let po_prepare = "";
            let po_chk = "";
            let po_app = "";
            let purchase = "";
            let purchase_status = "";
            let purchasemenu = "";
            let purchasemgt = this.state.matrix.filter(value => {
                if (value.object_name == "pr_prepare" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    pr_prepare =
                        <a href={myconfig.urlpr_prepare} className={this.state.pr_prepare}><i className="far fa-circle nav-icon"/>
                            <p>PR PREPARE</p></a>
                    return value;
                }
                if (value.object_name == "pr_check" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    pr_check =
                        <a href={myconfig.urlpr_check} className={this.state.pr_check}><i className="far fa-circle nav-icon"/>
                            <p>PR CHECK</p></a>
                    return value;
                } if (value.object_name == "pr_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    pr_app =
                        <a href={myconfig.urlpr_app} className={this.state.pr_app}><i className="far fa-circle nav-icon"/>
                            <p>PR APP</p></a>
                    return value;
                }if (value.object_name == "po_prepare" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    po_prepare =
                        <a href={myconfig.urlpo_prepare} className={this.state.po_prepare}><i className="far fa-circle nav-icon"/>
                            <p>PO PREPARE</p></a>
                    return value;
                }if (value.object_name == "po_chk" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    po_chk =
                        <a href={myconfig.urlpo_chk} className={this.state.po_chk}><i className="far fa-circle nav-icon"/>
                            <p>PO CHECK</p></a>
                    return value;
                }if (value.object_name == "po_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    po_app =
                        <a href={myconfig.urlpo_app} className={this.state.po_app}><i className="far fa-circle nav-icon"/>
                            <p>PO APP</p></a>
                    return value;
                }if (value.object_name == "purchase" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    purchase =
                        <a href={myconfig.urlpurchase} className={this.state.purchase}><i className="far fa-circle nav-icon"/>
                            <p>PURCHASE</p></a>
                    return value;
                }
                if (value.object_name == "purchase_status" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    purchase_status =
                        <a href={myconfig.urlpurchase_status} className={this.state.purchase_status}><i className="far fa-circle nav-icon"/>
                            <p>PURCHASE STATUS</p></a>
                    return value;
                }
            })
            if (purchasemgt.length > 0) {
                purchasemenu = <li name="purchasemgt" onClick={(event => {
                    this.menuclick(event, this.state.purchasemgt)
                })} className={this.state.purchasemgt}>
                    <a name="purchasemgt" onClick={(event => {
                        this.menuclick(event, this.state.purchasemgt)
                    })} href="#" className={this.state.purchasemgt1}>
                        <i className="nav-icon fas fa-plus-square"/>
                        <p>
                            Purchase
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {pr_prepare}
                            {pr_check}
                            {pr_app}
                            {po_prepare}
                            {po_chk}
                            {po_app}
                            {purchase}
                            {purchase_status}
                        </li>
                    </ul>
                </li>
            }


            let user = "";
            let objuser = "";
            let useraccess1 = "";
            let user_template = "";
            let usermenu = "";
            let usermgt = this.state.matrix.filter(value => {
                if (value.object_name == "user" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    user =
                        <a href={myconfig.urluser} className={this.state.user}><i className="far fa-circle nav-icon"/>
                            <p>User</p></a>
                    return value;
                }
                if (value.object_name == "objuser" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    objuser =
                        <a href={myconfig.urlobjuser} className={this.state.objuser}><i
                            className="far fa-circle nav-icon"/>
                            <p>User Object</p></a>
                    return value;
                }
                if (value.object_name == "useraccess1" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    useraccess1 =
                        <a href={myconfig.urluseraccess1} className={this.state.useraccess1}><i
                            className="far fa-circle nav-icon"/>
                            <p>User Access</p></a>
                    return value;
                } if (value.object_name == "user_template" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    user_template =
                        <a href={myconfig.urluser_template} className={this.state.user_template}><i
                            className="far fa-circle nav-icon"/>
                            <p>User Template</p></a>
                    return value;
                }
            })
            if (usermgt.length > 0) {
                usermenu = <li name="usermgt" onClick={(event => {
                    this.menuclick(event, this.state.usermgt)
                })} className={this.state.usermgt}>
                    <a name="usermgt" onClick={(event => {
                        this.menuclick(event, this.state.usermgt)
                    })} href="#" className={this.state.usermgt1}>
                        <i className="nav-icon fas fa-user"/>
                        <p>
                            User Management
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {user}
                            {objuser}
                            {useraccess1}
                            {user_template}
                        </li>
                    </ul>
                </li>
            }

            let status_purchase = "";
            let status_cement = "";
            let status_delivery = "";
            let status_cement_allocation = "";
            let status_purchase_detail = "";
            let status_cement_detail = "";
            let status_delivery_detail = "";
            let statusmenu = "";
            let statusmgt = this.state.matrix.filter(value => {
                if (value.object_name == "status_purchase" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    status_purchase =
                        <a href={myconfig.urlstatus_purchase} className={this.state.status_purchase}><i
                            className="far fa-circle nav-icon"/>
                            <p>Balance</p></a>
                    return value;
                }if (value.object_name == "status_cement" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    status_cement =
                        <a href={myconfig.urlstatus_cement} className={this.state.status_cement}><i
                            className="far fa-circle nav-icon"/>
                            <p>Cement</p></a>
                    return value;
                }if (value.object_name == "status_delivery" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    status_delivery =
                        <a href={myconfig.urlstatus_delivery} className={this.state.status_delivery}><i
                            className="far fa-circle nav-icon"/>
                            <p>Transport</p></a>
                    return value;
                }if (value.object_name == "status_cement_allocation" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    status_cement_allocation =
                        <a href={myconfig.urlstatus_cement_allocation} className={this.state.status_cement_allocation}><i
                            className="far fa-circle nav-icon"/>
                            <p>Cement Allocation</p></a>
                    return value;
                }if (value.object_name == "status_purchase_detail" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    status_purchase_detail =
                        <a href={myconfig.urlstatus_purchase_detail} className={this.state.status_purchase_detail}><i
                            className="far fa-circle nav-icon"/>
                            <p>Purchase Detail</p></a>
                    return value;
                }if (value.object_name == "status_cement_detail" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    status_cement_detail =
                        <a href={myconfig.urlstatus_cement_detail} className={this.state.status_cement_detail}><i
                            className="far fa-circle nav-icon"/>
                            <p>Cement Detail</p></a>
                    return value;
                }if (value.object_name == "status_delivery_detail" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    status_delivery_detail =
                        <a href={myconfig.urlstatus_delivery_detail} className={this.state.status_delivery_detail}><i
                            className="far fa-circle nav-icon"/>
                            <p>Transport Detail</p></a>
                    return value;
                }
            })
            if (statusmgt.length > 0) {
                statusmenu = <li name="statusmgt" onClick={(event => {
                    this.menuclick(event, this.state.statusmgt)
                })} className={this.state.statusmgt}>
                    <a name="statusmgt" onClick={(event => {
                        this.menuclick(event, this.state.statusmgt)
                    })} href="#" className={this.state.statusmgt1}>
                        <i className="nav-icon fas fa-columns"/>
                        <p>
                            Status
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {status_purchase}
                            {status_cement}
                            {status_delivery}
                            {status_cement_allocation}
                            {status_purchase_detail}
                            {status_cement_detail}
                            {status_delivery_detail}
                        </li>
                    </ul>
                </li>
            }

            let client_type = "";
            let client_mgt = "";
            let coc_mgt = "";
            let cargo_type = "";
            let admin_script = "";
            let managmentmenu = "";
            let managmentmgt = this.state.matrix.filter(value => {
                if (value.object_name == "client_type" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    client_type =
                        <a href={myconfig.urlclient_type} className={this.state.client_type}><i
                            className="far fa-circle nav-icon"/>
                            <p>Client Type</p></a>
                    return value;
                }
                if (value.object_name == "client_mgt" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    client_mgt =
                        <a href={myconfig.urlclient_mgt} className={this.state.client_mgt}><i
                            className="far fa-circle nav-icon"/>
                            <p>Client</p></a>
                    return value;
                }
                if (value.object_name == "coc_mgt" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    coc_mgt =
                        <a href={myconfig.urlcoc_mgt} className={this.state.coc_mgt}><i
                            className="far fa-circle nav-icon"/>
                            <p>Customs Office Code</p></a>
                    return value;
                }
                if (value.object_name == "cargo_type" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    cargo_type =
                        <a href={myconfig.urlcargo_type} className={this.state.cargo_type}><i
                            className="far fa-circle nav-icon"/>
                            <p>Cargo Type</p></a>
                    return value;
                } if (value.object_name == "admin_script" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    admin_script =
                        <a href={myconfig.urladmin_script} className={this.state.admin_script}><i
                            className="far fa-circle nav-icon"/>
                            <p>Admin Script</p></a>
                    return value;
                }
            })
            if (managmentmgt.length > 0) {
                managmentmenu = <li name="managmentmgt" onClick={(event => {
                    this.menuclick(event, this.state.managmentmgt)
                })} className={this.state.managmentmgt}>
                    <a name="managmentmgt" onClick={(event => {
                        this.menuclick(event, this.state.managmentmgt)
                    })} href="#" className={this.state.managmentmgt1}>
                        <i className="nav-icon fas fa-tools"/>
                        <p>
                            Client Management
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {client_type}
                            {client_mgt}
                            {/* {coc_mgt}*/}
                            {cargo_type}
                            {admin_script}
                        </li>
                    </ul>
                </li>
            }

            let os_company = "";
            let os_vehicle = "";
            let os_driver = "";
            let os_trailer = "";
            let assign_sub_contractor_req = "";
            let assign_sub_contractor_app = "";
            let sub_contractors = "";
            let supplier_company = "";
            let assign_supplier_company_req = "";
            let assign_supplier_company_app = "";
            let suppliers = "";
            let outsourcemenu = "";
            let outsourcemgt = this.state.matrix.filter(value => {
                if (value.object_name == "os_company" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    os_company =
                        <a href={myconfig.urlos_company} className={this.state.os_company}><i
                            className="far fa-circle nav-icon"/>
                            <p>Outsource Company</p></a>
                    return value;
                }  if (value.object_name == "os_vehicle" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    os_vehicle =
                        <a href={myconfig.urlos_vehicle} className={this.state.os_vehicle}><i
                            className="far fa-circle nav-icon"/>
                            <p>Outsource Vehicle</p></a>
                    return value;
                }if (value.object_name == "os_driver" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    os_driver =
                        <a href={myconfig.urlos_driver} className={this.state.os_driver}><i
                            className="far fa-circle nav-icon"/>
                            <p>Outsource Driver</p></a>
                    return value;
                }if (value.object_name == "os_trailer" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    os_trailer =
                        <a href={myconfig.urlos_trailer} className={this.state.os_trailer}><i
                            className="far fa-circle nav-icon"/>
                            <p>Outsource Trailer</p></a>
                    return value;
                }if (value.object_name == "assign_sub_contractor_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    assign_sub_contractor_req =
                        <a href={myconfig.urlassign_sub_contractor_req} className={this.state.assign_sub_contractor_req}><i
                            className="far fa-circle nav-icon"/>
                            <p>Sub-Contractor REQ</p></a>
                    return value;
                }
                if (value.object_name == "assign_sub_contractor_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    assign_sub_contractor_app =
                        <a href={myconfig.urlassign_sub_contractor_app} className={this.state.assign_sub_contractor_app}><i
                            className="far fa-circle nav-icon"/>
                            <p>Sub-Contractor APP</p></a>
                    return value;
                }if (value.object_name == "sub_contractors" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    sub_contractors =
                        <a href={myconfig.urlsub_contractors} className={this.state.sub_contractors}><i
                            className="far fa-circle nav-icon"/>
                            <p>Sub-Contractors</p></a>
                    return value;
                }if (value.object_name == "supplier_company" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    supplier_company =
                        <a href={myconfig.urlsupplier_company} className={this.state.supplier_company}><i
                            className="far fa-circle nav-icon"/>
                            <p>Supplier Company</p></a>
                    return value;
                }if (value.object_name == "assign_supplier_company_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    assign_supplier_company_req =
                        <a href={myconfig.urlassign_supplier_company_req} className={this.state.assign_supplier_company_req}><i
                            className="far fa-circle nav-icon"/>
                            <p>Supplier REQ</p></a>
                    return value;
                }if (value.object_name == "assign_supplier_company_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    assign_supplier_company_app =
                        <a href={myconfig.urlassign_supplier_company_app} className={this.state.assign_supplier_company_app}><i
                            className="far fa-circle nav-icon"/>
                            <p>Supplier APP</p></a>
                    return value;
                }if (value.object_name == "suppliers" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    suppliers =
                        <a href={myconfig.urlsuppliers} className={this.state.suppliers}><i
                            className="far fa-circle nav-icon"/>
                            <p>Suppliers</p></a>
                    return value;
                }
            })
            if (outsourcemgt.length > 0) {
                outsourcemenu = <li name="outsourcemgt" onClick={(event => {
                    this.menuclick(event, this.state.outsourcemgt)
                })} className={this.state.outsourcemgt}>
                    <a name="outsourcemgt" onClick={(event => {
                        this.menuclick(event, this.state.outsourcemgt)
                    })} href="#" className={this.state.outsourcemgt1}>
                        <i className="nav-icon fas fa-border"/>
                        <p>
                            Outsource
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {os_company}
                            {os_vehicle}
                            {os_driver}
                            {os_trailer}
                            {assign_sub_contractor_req}
                            {assign_sub_contractor_app}
                            {sub_contractors}
                            {supplier_company}
                            {assign_supplier_company_req}
                            {assign_supplier_company_app}
                            {suppliers}
                        </li>
                    </ul>
                </li>
            }

            let cement_open = "";
            let cement_app = "";
            let cement_so = "";
            let cement_current_status = "";
            let cement_update = "";
            let cement_update_app = "";
            let cement_status = "";
            let order_open = "";
            let order_accept = "";
            let order_accept_fin = "";
            let order_current_status = "";
            let order_update = "";
            let order_status = "";
            let order_detail = "";
            let order_op_update_app = "";
            let order_fin_update_app = "";
            let order_loading_adv_req= "";
            let order_loading_adv_app= "";
            let order_loading_adv= "";
            let cement_delivery= "";
            let cement_delivery_report= "";
            let cement_delivery_edit= "";
            let cement_delivery_cancel_req= "";
            let cement_delivery_cancel_app= "";
            let ordermenu = "";
            let ordermgt = this.state.matrix.filter(value => {
                if (value.object_name == "cement_open" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    cement_open =
                        <a href={myconfig.urlcement_open} className={this.state.cement_open}><i
                            className="far fa-circle nav-icon"/>
                            <p>Cement Open</p></a>
                    return value;
                } if (value.object_name == "cement_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    cement_app =
                        <a href={myconfig.urlcement_app} className={this.state.cement_app}><i
                            className="far fa-circle nav-icon"/>
                            <p>Cement APP</p></a>
                    return value;
                }if (value.object_name == "cement_so" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    cement_so =
                        <a href={myconfig.urlcement_so} className={this.state.cement_so}><i
                            className="far fa-circle nav-icon"/>
                            <p>Cement SO</p></a>
                    return value;
                }if (value.object_name == "cement_current_status" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    cement_current_status =
                        <a href={myconfig.urlcement_current_status} className={this.state.cement_current_status}><i
                            className="far fa-circle nav-icon"/>
                            <p>Cement Status</p></a>
                    return value;
                }if (value.object_name == "cement_update" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    cement_update =
                        <a href={myconfig.urlcement_update} className={this.state.cement_update}><i
                            className="far fa-circle nav-icon"/>
                            <p>Cement Update</p></a>
                    return value;
                }if (value.object_name == "cement_update_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    cement_update_app =
                        <a href={myconfig.urlcement_update_app} className={this.state.cement_update_app}><i
                            className="far fa-circle nav-icon"/>
                            <p>Cement Update APP</p></a>
                    return value;
                }if (value.object_name == "cement_status" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    cement_status =
                        <a href={myconfig.urlcement_status} className={this.state.cement_status}><i
                            className="far fa-circle nav-icon"/>
                            <p>Cement Status</p></a>
                    return value;
                }
                if (value.object_name == "order_open" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    order_open =
                        <a href={myconfig.urlorder_open} className={this.state.order_open}><i
                            className="far fa-circle nav-icon"/>
                            <p>Transport Open</p></a>
                    return value;
                }
                if (value.object_name == "order_accept_fin" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    order_accept_fin =
                        <a href={myconfig.urlorder_accept_fin} className={this.state.order_accept_fin}><i
                            className="far fa-circle nav-icon"/>
                            <p>Transport APP</p></a>
                    return value;
                }if (value.object_name == "order_current_status" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    order_current_status =
                        <a href={myconfig.urlorder_current_status} className={this.state.order_current_status}><i
                            className="far fa-circle nav-icon"/>
                            <p>Transport Status</p></a>
                    return value;
                }if (value.object_name == "order_update" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    order_update =
                        <a href={myconfig.urlorder_update} className={this.state.order_update}><i
                            className="far fa-circle nav-icon"/>
                            <p>Transport Update</p></a>
                    return value;
                }if (value.object_name == "order_op_update_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    order_op_update_app =
                        <a href={myconfig.urlorder_op_update_app} className={this.state.order_op_update_app}><i
                            className="far fa-circle nav-icon"/>
                            <p>OP Update APP</p></a>
                    return value;
                }if (value.object_name == "order_fin_update_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    order_fin_update_app =
                        <a href={myconfig.urlorder_fin_update_app} className={this.state.order_fin_update_app}><i
                            className="far fa-circle nav-icon"/>
                            <p>FIN Update APP</p></a>
                    return value;
                }if (value.object_name == "order_loading_adv_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    order_loading_adv_req =
                        <a href={myconfig.urlorder_loading_adv_req} className={this.state.order_loading_adv_req}><i
                            className="far fa-circle nav-icon"/>
                            <p>Transport LA Open</p></a>
                    return value;
                }if (value.object_name == "order_loading_adv_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    order_loading_adv_app =
                        <a href={myconfig.urlorder_loading_adv_app} className={this.state.order_loading_adv_app}><i
                            className="far fa-circle nav-icon"/>
                            <p>Transport    LA Populate</p></a>
                    return value;
                }if (value.object_name == "order_loading_adv" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    order_loading_adv =
                        <a href={myconfig.urlorder_loading_adv} className={this.state.order_loading_adv}><i
                            className="far fa-circle nav-icon"/>
                            <p>Transport LA</p></a>
                    return value;
                }if (value.object_name == "cement_delivery" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    cement_delivery =
                        <a href={myconfig.urlcement_delivery} className={this.state.cement_delivery}><i
                            className="far fa-circle nav-icon"/>
                            <p>CMT Allocation</p></a>
                    return value;
                }if (value.object_name == "cement_delivery_edit" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    cement_delivery_edit =
                        <a href={myconfig.urlcement_delivery_edit} className={this.state.cement_delivery_edit}><i
                            className="far fa-circle nav-icon"/>
                            <p>CMT Allocation Update</p></a>
                    return value;
                }if (value.object_name == "cement_delivery_report" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    cement_delivery_report =
                        <a href={myconfig.urlcement_delivery_report} className={this.state.cement_delivery_report}><i
                            className="far fa-circle nav-icon"/>
                            <p>CMT Allocation Report</p></a>
                    return value;
                }if (value.object_name == "cement_delivery_cancel_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    cement_delivery_cancel_req =
                        <a href={myconfig.urlcement_delivery_cancel_req} className={this.state.cement_delivery_cancel_req}><i
                            className="far fa-circle nav-icon"/>
                            <p>CMT Cancel REQ</p></a>
                    return value;
                }if (value.object_name == "cement_delivery_cancel_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    cement_delivery_cancel_app =
                        <a href={myconfig.urlcement_delivery_cancel_app} className={this.state.cement_delivery_cancel_app}><i
                            className="far fa-circle nav-icon"/>
                            <p>CMT Cancel APP</p></a>
                    return value;
                }if (value.object_name == "order_status" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    order_status =
                        <a href={myconfig.urlorder_status} className={this.state.order_status}><i
                            className="far fa-circle nav-icon"/>
                            <p>Order Status</p></a>
                    return value;
                }if (value.object_name == "order_detail" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    order_detail =
                        <a href={myconfig.urlorder_detail} className={this.state.order_detail}><i
                            className="far fa-circle nav-icon"/>
                            <p>Order Detail</p></a>
                    return value;
                }
            })
            if (ordermgt.length > 0) {
                ordermenu = <li name="ordermgt" onClick={(event => {
                    this.menuclick(event, this.state.ordermgt)
                })} className={this.state.ordermgt}>
                    <a name="ordermgt" onClick={(event => {
                        this.menuclick(event, this.state.ordermgt)
                    })} href="#" className={this.state.ordermgt1}>
                        <i className="nav-icon fas fa-file"/>
                        <p>
                            Sale Order
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {cement_open}
                            {cement_app}
                            {cement_so}
                            {cement_current_status}
                            {cement_update}
                          {/*  {cement_update}
                            {cement_update_app}*/}
                           {/* {cement_status}*/}
                            {order_open}
                            {order_accept_fin}
                            {order_accept}
                            {order_current_status}
                            {order_update}
                        {/*    {order_update}
                            {order_fin_update_app}
                            {order_op_update_app}*/}
                            {order_loading_adv_req}
                            {order_loading_adv_app}
                            {order_loading_adv}
                            {cement_delivery}
                            {cement_delivery_edit}
                            {cement_delivery_report}
                            {cement_delivery_cancel_req}
                            {cement_delivery_cancel_app}
                         {/*   {order_status}
                            {order_detail}*/}
                        </li>
                    </ul>
                </li>
            }


            let order = "";
            let assign_own = "";
            let update_allocate = "";
            let cancel_allocate = "";
            let special_allocate = "";
            let update_own = "";
            let delete_own = "";
            let payment_req = "";
            let payment_app = "";
            let payment_pay = "";
            let document_mgt = "";
            let void_fo = "";
            let veh_ava = "";
            let main_temp = "";
            let operationmenu = "";
            let operationmgt = this.state.matrix.filter(value => {
                if (value.object_name == "order" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    order =
                        <a href={myconfig.urlorder} className={this.state.order}><i
                            className="far fa-circle nav-icon"/>
                            <p>Order</p></a>
                    return value;
                } if (value.object_name == "assign_own" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    assign_own =
                        <a href={myconfig.urlassign_own} className={this.state.assign_own}><i
                            className="far fa-circle nav-icon"/>
                            <p>Allocate Vehicle</p></a>
                    return value;
                }if (value.object_name == "update_allocate" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    update_allocate =
                        <a href={myconfig.urlupdate_allocate} className={this.state.update_allocate}><i
                            className="far fa-circle nav-icon"/>
                            <p>Update Allocate</p></a>
                    return value;
                }if (value.object_name == "cancel_allocate" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    cancel_allocate =
                        <a href={myconfig.urlcancel_allocate} className={this.state.cancel_allocate}><i
                            className="far fa-circle nav-icon"/>
                            <p>Cancel Allocate</p></a>
                    return value;
                }
                if (value.object_name == "update_own" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    update_own =
                        <a href={myconfig.urlupdate_own} className={this.state.update_own}><i
                            className="far fa-circle nav-icon"/>
                            <p>Update Own</p></a>
                    return value;
                } if (value.object_name == "delete_own" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    delete_own =
                        <a href={myconfig.urldelete_own} className={this.state.delete_own}><i
                            className="far fa-circle nav-icon"/>
                            <p>Delete Own</p></a>
                    return value;
                }
                if (value.object_name == "payment_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    payment_req =
                        <a href={myconfig.urlpayment_req} className={this.state.payment_req}><i
                            className="far fa-circle nav-icon"/>
                            <p>Payment Request</p></a>
                    return value;
                }
                if (value.object_name == "payment_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    payment_app =
                        <a href={myconfig.urlpayment_app} className={this.state.payment_app}><i
                            className="far fa-circle nav-icon"/>
                            <p>Payment Approval</p></a>
                    return value;
                }
                if (value.object_name == "payment_pay" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    payment_pay =
                        <a href={myconfig.urlpayment_pay} className={this.state.payment_pay}><i
                            className="far fa-circle nav-icon"/>
                            <p>Payment</p></a>
                    return value;
                }if (value.object_name == "document_mgt" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    document_mgt =
                        <a href={myconfig.urldocument_mgt} className={this.state.document_mgt}><i
                            className="far fa-circle nav-icon"/>
                            <p>Document</p></a>
                    return value;
                }if (value.object_name == "void_fo" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    void_fo =
                        <a href={myconfig.urlvoid_fo} className={this.state.void_fo}><i
                            className="far fa-circle nav-icon"/>
                            <p>Void FO</p></a>
                    return value;
                }if (value.object_name == "veh_ava" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    veh_ava =
                        <a target="_blank" href={myconfig.urlveh_ava} className={this.state.veh_ava}><i
                            className="far fa-circle nav-icon"/>
                            <p>Vehicle Availability</p></a>
                    return value;
                }
                if (value.object_name == "main_temp" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    main_temp =
                        <a href={myconfig.urlmain_temp} className={this.state.main_temp}><i
                            className="far fa-circle nav-icon"/>
                            <p>Maintenance</p></a>
                    return value;
                }
            })
            if (operationmgt.length > 0) {
                operationmenu = <li name="operationmgt" onClick={(event => {
                    this.menuclick(event, this.state.operationmgt)
                })} className={this.state.operationmgt}>
                    <a name="operationmgt" onClick={(event => {
                        this.menuclick(event, this.state.operationmgt)
                    })} href="#" className={this.state.operationmgt1}>
                        <i className="nav-icon fas fa-box"/>
                        <p>
                            Operation
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {order}
                            {assign_own}
                            {update_allocate}
                            {/* {cancel_allocate}
                            {update_own}
                            {delete_own}
                            {payment_req}
                            {payment_app}
                            {payment_pay}*/}
                            {document_mgt}
                            {void_fo}
                            {main_temp}
                            {veh_ava}

                        </li>
                    </ul>
                </li>
            }

            let follow_up = "";
            let follow_up_2 = "";
            let follow_up_loading_unloading = "";
            let follow_up_transit = "";
            let follow_up_wfo = "";
            let vehicle_status = "";
            let follow_up_hisotry = "";
            let follow_up_movement = "";
            let follow_up_eta = "";
            let follow_up_stoppage = "";
            let follow_up_distance_calc = "";
            let follow_up_wizard = "";
            let follow_up_customer = "";
            let follow_up_report = "";
            let follow_up_manual = "";
            let controalmenu = "";
            let controalmgt = this.state.matrix.filter(value => {
                /* if (value.object_name == "follow_up" &&
                     (value.create || value.update || value.read || value.delete)
                 ) {
                     follow_up =
                         <a href={myconfig.urlfollow_up} className={this.state.follow_up}><i
                             className="far fa-circle nav-icon"/>
                             <p>Follow_up</p></a>
                     return value;
                 } */if (value.object_name == "follow_up_2" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    follow_up_2 =
                        <a href={myconfig.urlfollow_up_2} className={this.state.follow_up_2}><i
                            className="far fa-circle nav-icon"/>
                            <p>Follow_up</p></a>
                    return value;
                }if (value.object_name == "follow_up_loading_unloading" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    follow_up_loading_unloading =
                        <a href={myconfig.urlfollow_up_loading_unloading} className={this.state.follow_up_loading_unloading}><i
                            className="far fa-circle nav-icon"/>
                            <p>Follow Up Loading/Unloading</p></a>
                    return value;
                }if (value.object_name == "follow_up_transit" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    follow_up_transit =
                        <a href={myconfig.urlfollow_up_transit} className={this.state.follow_up_transit}><i
                            className="far fa-circle nav-icon"/>
                            <p>Follow Up Transit</p></a>
                    return value;
                }
                if (value.object_name == "follow_up_wfo" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    follow_up_wfo =
                        <a href={myconfig.urlfollow_up_wfo} className={this.state.follow_up_wfo}><i
                            className="far fa-circle nav-icon"/>
                            <p>Follow Up WFO</p></a>
                    return value;
                }
                if (value.object_name == "vehicle_status" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    vehicle_status =
                        <a href={myconfig.urlvehicle_status} className={this.state.vehicle_status}><i
                            className="far fa-circle nav-icon"/>
                            <p>Vehicle Status</p></a>
                    return value;
                }if (value.object_name == "follow_up_hisotry" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    follow_up_hisotry =
                        <a href={myconfig.urlfollow_up_hisotry} className={this.state.follow_up_hisotry}><i
                            className="far fa-circle nav-icon"/>
                            <p>Follow Up History</p></a>
                    return value;
                }if (value.object_name == "follow_up_movement" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    follow_up_movement =
                        <a href={myconfig.urlfollow_up_movement} className={this.state.follow_up_movement}><i
                            className="far fa-circle nav-icon"/>
                            <p>Movement Report</p></a>
                    return value;
                }
                if (value.object_name == "follow_up_eta" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    follow_up_eta =
                        <a href={myconfig.urlfollow_up_eta} className={this.state.follow_up_eta}><i
                            className="far fa-circle nav-icon"/>
                            <p>Follow Up ETA</p></a>
                    return value;
                } if (value.object_name == "follow_up_stoppage" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    follow_up_stoppage =
                        <a href={myconfig.urlfollow_up_stoppage} className={this.state.follow_up_stoppage}><i
                            className="far fa-circle nav-icon"/>
                            <p>Follow Up Stoppage</p></a>
                    return value;
                }
                if (value.object_name == "follow_up_distance_calc" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    follow_up_distance_calc =
                        <a href={myconfig.urlfollow_up_distance_calc} className={this.state.follow_up_distance_calc}><i
                            className="far fa-circle nav-icon"/>
                            <p>Distance Calc</p></a>
                    return value;
                }  if (value.object_name == "follow_up_wizard" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    follow_up_wizard =
                        <a href={myconfig.urlfollow_up_wizard} className={this.state.follow_up_wizard}><i
                            className="far fa-circle nav-icon"/>
                            <p>Follow Up Wizard</p></a>
                    return value;
                }
                if (value.object_name == "follow_up_customer" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    follow_up_customer =
                        <a href={myconfig.urlfollow_up_customer} className={this.state.follow_up_customer}><i
                            className="far fa-circle nav-icon"/>
                            <p>Follow Up Customer</p></a>
                    return value;
                }if (value.object_name == "follow_up_report" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    follow_up_report =
                        <a href={myconfig.urlfollow_up_report} className={this.state.follow_up_report}><i
                            className="far fa-circle nav-icon"/>
                            <p>Follow Up Report</p></a>
                    return value;
                }if (value.object_name == "follow_up_manual" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    follow_up_manual =
                        <a href={myconfig.urlfollow_up_manual} className={this.state.follow_up_manual}><i
                            className="far fa-circle nav-icon"/>
                            <p>Follow Up Manual</p></a>
                    return value;
                }
            })
            if (controalmgt.length > 0) {
                controalmenu = <li name="controalmgt" onClick={(event => {
                    this.menuclick(event, this.state.controalmgt)
                })} className={this.state.controalmgt}>
                    <a name="controalmgt" onClick={(event => {
                        this.menuclick(event, this.state.controalmgt)
                    })} href="#" className={this.state.controalmgt1}>
                        <i className="nav-icon fas fa-eye"/>
                        <p>
                            Controal
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {/* {follow_up}
                            {follow_up_2}*/}
                            {follow_up_loading_unloading}
                            {follow_up_transit}
                            {follow_up_wfo}
                            {follow_up_hisotry}
                            {follow_up_manual}
                            {follow_up_movement}
                            {follow_up_stoppage}
                            {follow_up_distance_calc}
                            {follow_up_wizard}
                            {/*  {follow_up_eta}*/}
                            {/*   {follow_up_stoppage}*/}
                            {/*   {follow_up_customer}*/}
                            {/*    {follow_up_report}*/}
                            {/*  {vehicle_status}*/}
                        </li>
                    </ul>
                </li>
            }

            let vehicle = "";
            let driver = "";
            let trailer = "";
            let location = "";
            let road_route = "";
            let road_route_open = "";
            let other_cost = "";
            let status_type = "";
            let fuel_rate = "";
            let assetmenu = "";
            let assetmgt = this.state.matrix.filter(value => {
                if (value.object_name == "vehicle" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    vehicle =
                        <a href={myconfig.urlvehicle} className={this.state.vehicle}><i
                            className="far fa-circle nav-icon"/>
                            <p>Vehicle</p></a>
                    return value;
                }
                if (value.object_name == "driver" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    driver =
                        <a href={myconfig.urldriver} className={this.state.driver}><i
                            className="far fa-circle nav-icon"/>
                            <p>Driver</p></a>
                    return value;
                } if (value.object_name == "trailer" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    trailer =
                        <a href={myconfig.urltrailer} className={this.state.trailer}><i
                            className="far fa-circle nav-icon"/>
                            <p>Trailer</p></a>
                    return value;
                }if (value.object_name == "location" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    location =
                        <a href={myconfig.urllocation} className={this.state.location}><i
                            className="far fa-circle nav-icon"/>
                            <p>Location</p></a>
                    return value;
                }if (value.object_name == "road_route" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    road_route =
                        <a href={myconfig.urlroad_route} className={this.state.road_route}><i
                            className="far fa-circle nav-icon"/>
                            <p>Road Route</p></a>
                    return value;
                }if (value.object_name == "road_route_open" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    road_route_open =
                        <a href={myconfig.urlroad_route_open} className={this.state.road_route_open}><i
                            className="far fa-circle nav-icon"/>
                            <p>Road Route Open</p></a>
                    return value;
                }
                if (value.object_name == "other_cost" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    other_cost =
                        <a href={myconfig.urlother_cost} className={this.state.other_cost}><i
                            className="far fa-circle nav-icon"/>
                            <p>Other Cost</p></a>
                    return value;
                }
                if (value.object_name == "status_type" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    status_type =
                        <a href={myconfig.urlstatus_type} className={this.state.status_type}><i
                            className="far fa-circle nav-icon"/>
                            <p>Status Type</p></a>
                    return value;
                }if (value.object_name == "fuel_rate" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    fuel_rate =
                        <a href={myconfig.urlfuel_rate} className={this.state.fuel_rate}><i
                            className="far fa-circle nav-icon"/>
                            <p>Fuel Rate</p></a>
                    return value;
                }
            })
            if (assetmgt.length > 0) {
                assetmenu = <li name="assetmgt" onClick={(event => {
                    this.menuclick(event, this.state.assetmgt)
                })} className={this.state.assetmgt}>
                    <a name="assetmgt" onClick={(event => {
                        this.menuclick(event, this.state.assetmgt)
                    })} href="#" className={this.state.assetmgt1}>
                        <i className="nav-icon fas fa-address-book"/>
                        <p>
                            Assets
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {vehicle}
                            {driver}
                            {trailer}
                            {location}
                            {road_route_open}
                            {road_route}
                            {/*{other_cost}*/}
                            {/*{status_type}*/}
                            {fuel_rate}
                        </li>
                    </ul>
                </li>
            }

            let finance_fo_by_date = "";
            let finance_fo_by_plate_no = "";
            let finance_canceled_fo = "";
            let finance_doc_rec = "";
            let finance_not_doc_rec = "";
            let financemenu = "";
            let financemgt = this.state.matrix.filter(value => {
                if (value.object_name == "finance_fo_by_date" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    finance_fo_by_date =
                        <a href={myconfig.urlfinance_fo_by_date} className={this.state.finance_fo_by_date}><i
                            className="far fa-circle nav-icon"/>
                            <p>Fo By Date</p></a>
                    return value;
                }
                if (value.object_name == "finance_fo_by_plate_no" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    finance_fo_by_plate_no =
                        <a href={myconfig.urlfinance_fo_by_plate_no} className={this.state.finance_fo_by_plate_no}><i
                            className="far fa-circle nav-icon"/>
                            <p>Fo By Plate #</p></a>
                    return value;
                }
                if (value.object_name == "finance_canceled_fo" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    finance_canceled_fo =
                        <a href={myconfig.urlfinance_canceled_fo} className={this.state.finance_canceled_fo}><i
                            className="far fa-circle nav-icon"/>
                            <p>Canceled Fo</p></a>
                    return value;
                }if (value.object_name == "finance_doc_rec" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    finance_doc_rec =
                        <a href={myconfig.urlfinance_doc_rec} className={this.state.finance_doc_rec}><i
                            className="far fa-circle nav-icon"/>
                            <p>Document Rec'd</p></a>
                    return value;
                }if (value.object_name == "finance_not_doc_rec" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    finance_not_doc_rec =
                        <a href={myconfig.urlfinance_not_doc_rec} className={this.state.finance_not_doc_rec}><i
                            className="far fa-circle nav-icon"/>
                            <p>Document Not Rec'd</p></a>
                    return value;
                }
            })
            if (financemgt.length > 0) {
                financemenu = <li name="financemgt" onClick={(event => {
                    this.menuclick(event, this.state.financemgt)
                })} className={this.state.financemgt}>
                    <a name="financemgt" onClick={(event => {
                        this.menuclick(event, this.state.financemgt)
                    })} href="#" className={this.state.financemgt1}>
                        <i className="nav-icon fas fa-chart-pie"/>
                        <p>
                            Finance
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {finance_fo_by_date}
                            {finance_fo_by_plate_no}
                            {finance_canceled_fo}
                            {finance_doc_rec}
                            {finance_not_doc_rec}
                        </li>
                    </ul>
                </li>
            }

            /* let insurance_type = "";
             let vehicle_insurance = "";
             let driver_insurance = "";
             let trailer_insurance = "";
             let insurancemenu = "";
             let insurancemgt = this.state.matrix.filter(value => {
                 if (value.object_name == "vehicle_insurance" &&
                     (value.create || value.update || value.read || value.delete)
                 ) {
                     vehicle_insurance =
                         <a href={myconfig.urlvehicle_insurance} className={this.state.vehicle_insurance}><i
                             className="far fa-circle nav-icon"/>
                             <p>Vehicle Insurance</p></a>
                     return value;
                 } if (value.object_name == "insurance_type" &&
                     (value.create || value.update || value.read || value.delete)
                 ) {
                     insurance_type =
                         <a href={myconfig.urlinsurance_type} className={this.state.insurance_type}><i
                             className="far fa-circle nav-icon"/>
                             <p>Insurance</p></a>
                     return value;
                 }
                 if (value.object_name == "driver_insurance" &&
                     (value.create || value.update || value.read || value.delete)
                 ) {
                     driver_insurance =
                         <a href={myconfig.urldriver_insurance} className={this.state.driver_insurance}><i
                             className="far fa-circle nav-icon"/>
                             <p>Driver Insurance</p></a>
                     return value;
                 } if (value.object_name == "trailer_insurance" &&
                     (value.create || value.update || value.read || value.delete)
                 ) {
                     trailer_insurance =
                         <a href={myconfig.urltrailer_insurance} className={this.state.trailer_insurance}><i
                             className="far fa-circle nav-icon"/>
                             <p>Trailer Insurance</p></a>
                     return value;
                 }
             })
             if (insurancemgt.length > 0) {
                 insurancemenu = <li name="insurancemgt" onClick={(event => {
                     this.menuclick(event, this.state.insurancemgt)
                 })} className={this.state.insurancemgt}>
                     <a name="insurancemgt" onClick={(event => {
                         this.menuclick(event, this.state.insurancemgt)
                     })} href="#" className={this.state.insurancemgt1}>
                         <i className="nav-icon fas fa-edit"/>
                         <p>
                             Insurance
                             <i className="right fas fa-angle-left"/>
                         </p>
                     </a>
                     <ul className="nav nav-treeview">
                         <li className="nav-item">
                             {insurance_type}
                             {vehicle_insurance}
                             {driver_insurance}
                             {trailer_insurance}
                         </li>
                     </ul>
                 </li>
             }*/



            let singlemenu_changepwd = this.state.matrix.filter(value => {
                if (value.object_name == "changepwd" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            if (singlemenu_changepwd.length > 0)
                singlemenu_changepwd = <li className="nav-item">
                    <a href="" onClick={this.showcu} className={this.state.changepwd}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Change Password</p>
                    </a>
                </li>
            else
                singlemenu_changepwd = null;

            let report_generator = this.state.matrix.filter(value => {
                if (value.object_name == "report_generator" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            if (report_generator.length > 0)
                report_generator = <li className="nav-item">
                    <a href={myconfig.urlreport_generator} className={this.state.report_generator}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Report</p>
                    </a>
                </li>
            else
                report_generator = null;

            /*            let r_trip_master = "";
                        let r_load_factor = "";
                        let r_maintance = "";
                        let r_ava = "";
                        let r_tat = "";
                        let r_detail_tat = "";
                        let r_fright_order = "";
                        let r_daily_stoppage = "";
                        let r_movement = "";
                        let r_tac = "";
                        let r_tac_current = "";
                        let r_time_to_load = "";
                        let r_time_to_load_open = "";
                        let reportmenu = "";
                        let reportmgt = this.state.matrix.filter(value => {
                            if (value.object_name == "r_trip_master" &&
                                (value.create || value.update || value.read || value.delete)
                            ) {
                                r_trip_master =
                                    <a href={myconfig.urlr_trip_master} className={this.state.r_trip_master}><i
                                        className="far fa-circle nav-icon"/>
                                        <p>Master Trip</p></a>
                                return value;
                            } if (value.object_name == "r_load_factor" &&
                                (value.create || value.update || value.read || value.delete)
                            ) {
                                r_load_factor =
                                    <a href={myconfig.urlr_load_factor} className={this.state.r_load_factor}><i
                                        className="far fa-circle nav-icon"/>
                                        <p>Load Factor</p></a>
                                return value;
                            }if (value.object_name == "r_maintance" &&
                                (value.create || value.update || value.read || value.delete)
                            ) {
                                r_maintance =
                                    <a href={myconfig.urlr_maintance} className={this.state.r_maintance}><i
                                        className="far fa-circle nav-icon"/>
                                        <p>Maintenance</p></a>
                                return value;
                            }if (value.object_name == "r_ava" &&
                                (value.create || value.update || value.read || value.delete)
                            ) {
                                r_ava =
                                    <a href={myconfig.urlr_ava} className={this.state.r_ava}><i
                                        className="far fa-circle nav-icon"/>
                                        <p>Availability</p></a>
                                return value;
                            }if (value.object_name == "r_tat" &&
                                (value.create || value.update || value.read || value.delete)
                            ) {
                                r_tat =
                                    <a href={myconfig.urlr_tat} className={this.state.r_tat}><i
                                        className="far fa-circle nav-icon"/>
                                        <p>TAT</p></a>
                                return value;
                            }if (value.object_name == "r_detail_tat" &&
                                (value.create || value.update || value.read || value.delete)
                            ) {
                                r_detail_tat =
                                    <a href={myconfig.urlr_detail_tat} className={this.state.r_detail_tat}><i
                                        className="far fa-circle nav-icon"/>
                                        <p>Detail TAT</p></a>
                                return value;
                            }if (value.object_name == "r_fright_order" &&
                                (value.create || value.update || value.read || value.delete)
                            ) {
                                r_fright_order =
                                    <a href={myconfig.urlr_fright_order} className={this.state.r_fright_order}><i
                                        className="far fa-circle nav-icon"/>
                                        <p>Fright Order</p></a>
                                return value;
                            }if (value.object_name == "r_daily_stoppage" &&
                                (value.create || value.update || value.read || value.delete)
                            ) {
                                r_daily_stoppage =
                                    <a href={myconfig.urlr_daily_stoppage} className={this.state.r_daily_stoppage}><i
                                        className="far fa-circle nav-icon"/>
                                        <p>Daily Stoppage</p></a>
                                return value;
                            }if (value.object_name == "r_movement" &&
                                (value.create || value.update || value.read || value.delete)
                            ) {
                                r_movement =
                                    <a href={myconfig.urlr_movement} className={this.state.r_movement}><i
                                        className="far fa-circle nav-icon"/>
                                        <p>Movement</p></a>
                                return value;
                            }if (value.object_name == "r_tac" &&
                                (value.create || value.update || value.read || value.delete)
                            ) {
                                r_tac =
                                    <a href={myconfig.urlr_tac} className={this.state.r_tac}><i
                                        className="far fa-circle nav-icon"/>
                                        <p>Time to Offload Closed</p></a>
                                return value;
                            }if (value.object_name == "r_tac_current" &&
                                (value.create || value.update || value.read || value.delete)
                            ) {
                                r_tac_current =
                                    <a href={myconfig.urlr_tac_current} className={this.state.r_tac_current}><i
                                        className="far fa-circle nav-icon"/>
                                        <p>Time to Offload Open</p></a>
                                return value;
                            }if (value.object_name == "r_time_to_load" &&
                                (value.create || value.update || value.read || value.delete)
                            ) {
                                r_time_to_load =
                                    <a href={myconfig.urlr_time_to_load} className={this.state.r_time_to_load}><i
                                        className="far fa-circle nav-icon"/>
                                        <p>Time to Load Closed</p></a>
                                return value;
                            }if (value.object_name == "r_time_to_load_open" &&
                                (value.create || value.update || value.read || value.delete)
                            ) {
                                r_time_to_load_open =
                                    <a href={myconfig.urlr_time_to_load_open} className={this.state.r_time_to_load_open}><i
                                        className="far fa-circle nav-icon"/>
                                        <p>Time to Load Open</p></a>
                                return value;
                            }
                        })
                        if (reportmgt.length > 0) {
                            reportmenu = <li name="reportmgt" onClick={(event => {
                                this.menuclick(event, this.state.reportmgt)
                            })} className={this.state.reportmgt}>
                                <a name="reportmgt" onClick={(event => {
                                    this.menuclick(event, this.state.reportmgt)
                                })} href="#" className={this.state.reportmgt1}>
                                    <i className="nav-icon fas fa-book"/>
                                    <p>
                                        Report
                                        <i className="right fas fa-angle-left"/>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        {r_trip_master}
                                        {r_load_factor}
                                        {r_maintance}
                                        {r_ava}
                                        {r_fright_order}
                                        {r_daily_stoppage}
                                        {r_movement}
                                        {r_tat}
                                        {r_detail_tat}
                                        {r_tac}
                                        {r_tac_current}
                                        {r_time_to_load}
                                        {r_time_to_load_open}
                                    </li>
                                </ul>
                            </li>
                        }*/
            let kpi_umo = "";
            let kpi_description = "";
            let kpi_data = "";
            let kpiplanmenu = "";
            let kpiplanmgt = this.state.matrix.filter(value => {
                if (value.object_name == "kpi_umo" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    kpi_umo =
                        <a href={myconfig.urlkpi_umo} className={this.state.kpi_umo}><i
                            className="far fa-circle nav-icon"/>
                            <p>UoM</p></a>
                    return value;
                }
                if (value.object_name == "kpi_description" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    kpi_description =
                        <a href={myconfig.urlkpi_description} className={this.state.kpi_description}><i
                            className="far fa-circle nav-icon"/>
                            <p>KPI</p></a>
                    return value;
                }
                if (value.object_name == "kpi_data" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    kpi_data =
                        <a href={myconfig.urlkpi_data} className={this.state.kpi_data}><i
                            className="far fa-circle nav-icon"/>
                            <p>KPI Data</p></a>
                    return value;
                }
            })
            if (kpiplanmgt.length > 0) {
                kpiplanmenu = <li name="kpiplanmgt" onClick={(event => {
                    this.menuclick(event, this.state.kpiplanmgt)
                })} className={this.state.kpiplanmgt}>
                    <a name="kpiplanmgt" onClick={(event => {
                        this.menuclick(event, this.state.kpiplanmgt)
                    })} href="#" className={this.state.kpiplanmgt1}>
                        <i className="nav-icon fas fa-arrow-circle-down"/>
                        <p>
                            KPI & Planning
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {kpi_umo}
                            {kpi_description}
                            {kpi_data}
                        </li>
                    </ul>
                </li>
            }

            let g_in_req = "";
            let g_in_app = "";
            let g_out_req = "";
            let g_out_app = "";
            let g_status = "";
            let garagemenu = "";
            let garagemgt = this.state.matrix.filter(value => {
                if (value.object_name == "g_in_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    g_in_req =
                        <a href={myconfig.urlg_in_req} className={this.state.g_in_req}><i
                            className="far fa-circle nav-icon"/>
                            <p>In Request</p></a>
                    return value;
                }
                if (value.object_name == "g_in_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    g_in_app =
                        <a href={myconfig.urlg_in_app} className={this.state.g_in_app}><i
                            className="far fa-circle nav-icon"/>
                            <p>In Approve</p></a>
                    return value;
                } if (value.object_name == "g_out_req" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    g_out_req =
                        <a href={myconfig.urlg_out_req} className={this.state.g_out_req}><i
                            className="far fa-circle nav-icon"/>
                            <p>Out Request</p></a>
                    return value;
                }
                if (value.object_name == "g_out_app" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    g_out_app =
                        <a href={myconfig.urlg_out_app} className={this.state.g_out_app}><i
                            className="far fa-circle nav-icon"/>
                            <p>Out Approve</p></a>
                    return value;
                }
                if (value.object_name == "g_status" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    g_status =
                        <a href={myconfig.urlg_status} className={this.state.g_status}><i
                            className="far fa-circle nav-icon"/>
                            <p>Status</p></a>
                    return value;
                }
            })
            if (garagemgt.length > 0) {
                garagemenu = <li name="garagemgt" onClick={(event => {
                    this.menuclick(event, this.state.garagemgt)
                })} className={this.state.garagemgt}>
                    <a name="garagemgt" onClick={(event => {
                        this.menuclick(event, this.state.garagemgt)
                    })} href="#" className={this.state.garagemgt1}>
                        <i className="nav-icon fas fa-arrow-circle-down"/>
                        <p>
                            Maintenance
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {g_in_req}
                            {g_in_app}
                            {g_out_req}
                            {g_out_app}
                            {g_status}
                        </li>
                    </ul>
                </li>
            }

            return <>
                {dashmenu}
                {usermenu}
                {managmentmenu}
                {kpiplanmenu}
                {assetmenu}
                {statusmenu}
                {purchasemenu}
                {ordermenu}
                {operationmenu}
                {oprefundmenu}
                {controalmenu}
                {garagemenu}
                {financemenu}
                {opexmenu}
                {outsourcemenu}
                {telegrammenu}
                {/*{reportmenu}*/}
                {/*  {insurancemenu}*/}
                {report_generator}
                {singlemenu_changepwd}
            </>
        } else {
            return (<>
                {}
            </>)
        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    modalcu = () => {
        let displayed = <div className="row">
            <div className="col-12 px-3">
                <div className="form-group">
                    <label>Current Password</label>
                    <input className="form-control" type="password" name="current_password"
                           value={this.state.current_password} onChange={this.inputmgt}/>
                </div>
            </div>
            <div className="col-12 px-3">
                <div className="form-group">
                    <label>New Password</label>
                    <input className="form-control" type="password" name="new_password"
                           value={this.state.new_password} onChange={this.inputmgt}/>
                </div>
            </div>
            <div className="col-12 px-3">
                <div className="form-group">
                    <label>Confirm Password</label>
                    <input className="form-control" type="password"
                           name="confirm_password" value={this.state.confirm_password} onChange={this.inputmgt}/>
                </div>
            </div>
            <div className="col-md-12 px-3">
                <div className="form-group d-flex justify-content-around">
                    <button className="btn btn-outline-primary" onClick={this.submitcu}> Save</button>
                    <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel</button>
                </div>
            </div>
        </div>;
        return (<Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>Change Password</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    {displayed}
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    showcu = (event) => {
        event.preventDefault()
        this.setState({
            cushow: true,
        })
    }
    hidecu = () => {
        this.setState({
            cushow: false,
        })
    }
    submitcu = (event) => {
        event.preventDefault()
        let baseurl = myconfig.changepwd_by_user;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            current_password: this.state.current_password,
            new_password: this.state.new_password,
            confirm_password: this.state.confirm_password,
            current_pin: this.state.current_pin,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    cushow: false,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    backup_file = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.r_backup;
        if(this.state.isexe===false){
            this.setState({
                isexe: true,
            },()=>{
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        const a = document.createElement("a");
                        a.href = URL.createObjectURL(new Blob([JSON.stringify(res.data.datas, null, 2)], {
                            type: "text/plain"
                        }));
                        a.setAttribute("download", res.data.file_name+".txt");
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        })
                        this.setState({
                            isexe: false,
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            })

        }
    }
    menuclick = (event, value) => {
        var name = event.target.name;
        if (value == 'nav-item')
            value = 'nav-item menu-open active';
        else
            value = 'nav-item'
        this.setState({
            [name]: value,
        })
    }

    render() {
        return (
            <>
                <nav className="main-header navbar navbar-expand navbar-white navbar-light text-sm">
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i
                                className="fas fa-bars"/></a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <a href="/" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <a href="#" className="nav-link">Contact</a>
                        </li>
                    </ul>
                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="fullscreen" href="" onClick={this.logout}
                               role="button">
                                <i className="fas fa-sign-out-alt"/>
                            </a>
                        </li>
                    </ul>
                </nav>
                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    <a href="#" className="brand-link text-sm">
                        <img src={logimg} alt="ZTS Ethiopia" className="brand-image img-circle elevation-5"
                             style={{opacity: '.8'}}/>
                        <span className="brand-text font-weight-light">ZTS Ethiopia</span>
                    </a>
                    {/* Sidebar */}
                    <div className="sidebar">
                        {/* Sidebar user panel (optional) */}
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                            </div>
                            <div className="info">
                                <a href="#" className="d-block">{this.state.user_name}</a>
                            </div>
                        </div>
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column text-sm" data-widget="treeview"
                                role="menu" data-accordion="false">
                                {this.displaymenus()}
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>
                {this.modalcu()}
            </>
        );
    }
}
export default Nav;
