import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Tg_stop_at_client extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filters: [],
            wiz:1,
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_message_for_tg_stop_at_client;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            }
            else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
            else {
                this.setState({
                    datas: res.data.datas,
                    filters: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    btnsend = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.send_message_for_tg_stop_at_client;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            datas:this.state.filters,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let count = 0;
        let tdata = this.state.filters.map(value => {
            count = count + 1;
            return (<tr>
                <td>{count}</td>
                <td><a href="" onClick={event => {
                    event.preventDefault();
                    let datas = this.state.datas.filter(v => {
                        if (v.vehicle_id !== value.vehicle_id)
                            return v;
                    })
                    let filters = this.state.filters.filter(v => {
                        if (v.vehicle_id !== value.vehicle_id)
                            return v;
                    })
                    this.setState({
                        datas: datas,
                        filters: filters,
                    })

                }}>Remove</a></td>
                <td>{value.plate_no}</td>
                <td>{value.status}</td>
                <td>{value.client}</td>
                <td>{value.location}</td>
                <td>{value.dur_con}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-2">Plate #</th>
                            <th className="col-md-2">Status</th>
                            <th className="col-md-2">Client</th>
                            <th className="col-md-2">Location</th>
                            <th className="col-md-2">Duration</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn float-right"
                        style={{backgroundColor: myconfig.color_1, color: myconfig.color_3}}
                        onClick={this.btnsend}>
                    Send Message
                </button>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="tg_stop_at_client"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Telegram Stop at Client</h3>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Tg_stop_at_client;
